import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import React, { useState } from 'react'
import Input from '../input'

type PasswordInputProps = {
  onChange?: () => void
  placeholder: string
  name: string
  validation?: Record<string, unknown>
  autofill?: boolean
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  onChange,
  placeholder,
  name,
  validation,
  autofill = true,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault()
  }

  return (
    <Input
      autofill={autofill}
      placeholder={placeholder}
      type={showPassword ? 'text' : 'password'}
      name={name}
      validation={validation}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            color="secondary"
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
      onChange={onChange}
    />
  )
}

export default PasswordInput
