import { Box } from '@mui/material'
import React, { ReactNode } from 'react'

type FieldContainerProps = {
  children?: ReactNode
  className?: string
}

const FieldContainer: React.FC<FieldContainerProps> = props => {
  return (
    <Box
      sx={{
        minHeight: '72px',
      }}
      className={props.className}
    >
      {props.children}
    </Box>
  )
}

export default FieldContainer
