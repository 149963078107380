import { useQuery } from '@apollo/client'
import { Autocomplete, TextField, Tooltip } from '@mui/material'
import { useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ADMIN_ROLE, COMPANY_VIEW_ROLE } from '../../../../../Common/constants'
import { ClientContext, UserInfoContext } from '../../../../../Context'
import { GET_CLIENTS } from '../../../../../Graphql/query'
import type { Client } from '../../../../../__generated__/graphql'
import { useGoogleAnalytics } from '../../../../../hooks/useGoogleAnalytics'

export const ClientDropdown = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { userInfo } = useContext(UserInfoContext)
  const { client, setClient } = useContext(ClientContext)
  const customEvent = useGoogleAnalytics()

  const [clients, setClients] = useState<Client[]>([])

  const clientCode = searchParams.get('client')

  const isLimitedAccess = !(
    userInfo?.role === ADMIN_ROLE || userInfo?.role === COMPANY_VIEW_ROLE
  )
  useQuery(GET_CLIENTS, {
    skip: isLimitedAccess,
    onCompleted: data => {
      const client = clientCode
        ? data.clients.find(c => c.code === clientCode)
        : data.clients.at(0)
      setClients(data.clients)
      setClient?.({
        clientId: client?.id,
        clientCode: client?.code,
        clientName: client?.name,
      })
    },
  })

  const onClientChange = (client?: Client) => {
    setClient?.({
      clientId: client?.id,
      clientCode: client?.code,
      clientName: client?.name,
    })

    customEvent('change_client', {
      selected_client: client?.code,
    })

    if (client?.code) {
      setSearchParams({ client: client.code })
    }
  }

  if (!clients.length) return null

  const defaultClient = clientCode
    ? clients.find(c => c.code === clientCode)
    : clients.at(0)

  return (
    <Tooltip title={client?.clientName ?? ''} placement="bottom">
      <Autocomplete
        disabled={isLimitedAccess}
        sx={{
          minWidth: 200,
        }}
        autoHighlight
        disableClearable
        onChange={(event, value) => onClientChange(value)}
        defaultValue={defaultClient}
        id="client"
        options={clients}
        getOptionLabel={opt => opt.name}
        groupBy={opt => {
          const firstLetter = opt.name[0].toUpperCase()
          return /[0-9]/.test(firstLetter) ? '0-9' : firstLetter
        }}
        renderInput={params => <TextField {...params} variant="standard" />}
      ></Autocomplete>
    </Tooltip>
  )
}
