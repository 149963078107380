import { useQuery } from '@apollo/client'
import { AppBar, Box, Drawer, Toolbar, useTheme } from '@mui/material'
import {
  ADMIN_ROLE,
  APP_BAR_HEIGHT,
  COMPANY_VIEW_ROLE,
  DRAWER_WIDTH,
  FONT_FAMILY,
  FOOTER_HEIGHT,
  ROLES,
} from 'Common/constants'
import * as ROUTES from 'Config/routes'
import { ClientContext, UserInfoContext } from 'Context'
import { GET_CURRENT_USER } from 'Graphql/query'
import { initialize as initializePendo } from 'Utils/pendo'
import Highcharts from 'highcharts/highstock'
import Indicators from 'highcharts/indicators/indicators'
import Macd from 'highcharts/indicators/macd'
import PivotPoints from 'highcharts/indicators/pivot-points'
import Accessibility from 'highcharts/modules/accessibility'
import Annotations from 'highcharts/modules/annotations'
import Data from 'highcharts/modules/data'
import ExportMap from 'highcharts/modules/export-data'
import Exporting from 'highcharts/modules/exporting'
import Map from 'highcharts/modules/map'
import Sunburst from 'highcharts/modules/sunburst'
import { useContext, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useNavigate } from 'react-router'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import ErrorBoundaryFallback from './components/error-boundary-fallback'
import Footer from './components/footer'
import Header from './components/header'
import { Navigation } from './components/navigation/Navigation'
import Routes from './routes'

// Load Highcharts modules
Indicators(Highcharts)
PivotPoints(Highcharts)
Macd(Highcharts)
Data(Highcharts)
Sunburst(Highcharts)
Exporting(Highcharts)
ExportMap(Highcharts)
Map(Highcharts)
Accessibility(Highcharts)
Annotations(Highcharts)

Highcharts.setOptions({
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    zooming: {
      mouseWheel: false,
      singleTouch: false,
    },
    style: {
      fontFamily: FONT_FAMILY,
    },
  },
  legend: {
    itemStyle: {
      fontFamily: FONT_FAMILY,
    },
  },
  xAxis: {
    labels: {
      style: {
        fontFamily: FONT_FAMILY,
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        fontFamily: FONT_FAMILY,
      },
    },
  },
})

const Dashboard = () => {
  const theme = useTheme()
  const { setUserInfo } = useContext(UserInfoContext)
  const { setClient } = useContext(ClientContext)

  const [enabledDarwinV2] = useFeatureFlags('feat_darwin-v2')

  const navigate = useNavigate()

  useEffect(() => {
    if (!enabledDarwinV2) {
      navigate(
        {
          pathname: `${ROUTES.DASHBOARD}`,
        },
        { replace: true },
      )
    }
  }, [enabledDarwinV2, navigate])

  useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: ({ currentUser }) => {
      if (!currentUser) {
        navigate({
          pathname: `${ROUTES.SIGN_IN}`,
        })
        return
      }

      if (currentUser?.settings?.mustResetPassword) {
        navigate({
          pathname: `${ROUTES.CHANGE_PASSWORD}`,
        })
        return
      }

      const { email, roles } = currentUser
      const ranks = roles.map(role => role.rank)
      const role = ROLES[Math.min(...ranks) as keyof typeof ROLES]
      setUserInfo?.({
        email,
        role: role,
      })
      if (role !== ADMIN_ROLE && role !== COMPANY_VIEW_ROLE) {
        setClient?.({
          clientId: currentUser?.clientId,
          clientCode: currentUser?.clientCode,
          clientName: currentUser?.clientName,
        })
      }

      initializePendo({
        visitor: {
          id: currentUser?.emailHash,
          email: currentUser?.emailHash,
          role: role,
        },
        account: {
          id: currentUser?.clientCode ?? '',
          code: currentUser?.clientCode ?? '',
        },
      })
    },
    onError: () => {
      navigate({
        pathname: `${ROUTES.SIGN_IN}`,
      })
    },
  })

  const [navigationOpen, setNavigationOpen] = useState(false)

  if (!enabledDarwinV2) {
    return null
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          maxWidth: '1440px',
          margin: '0 auto',
        }}
      >
        <AppBar
          elevation={0}
          position="fixed"
          sx={{
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Toolbar>
            <Header />
          </Toolbar>
        </AppBar>

        <Drawer
          elevation={0}
          open
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          variant="permanent"
        >
          <Toolbar />
          <Navigation
            open={navigationOpen}
            handleClose={() => setNavigationOpen(false)}
          />
        </Drawer>

        <Box
          sx={{
            display: 'flex',
            flex: 1,
            overflow: 'hidden',
            marginLeft: { sm: 0, md: DRAWER_WIDTH },
            marginTop: APP_BAR_HEIGHT,
            marginBottom: FOOTER_HEIGHT,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              backgroundColor: theme.palette.background.default,
              overflow: 'auto',
            }}
          >
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
              <Routes />
            </ErrorBoundary>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100vw',
          zIndex: theme.zIndex.drawer,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Footer />
      </Box>
    </Box>
  )
}

export default Dashboard
