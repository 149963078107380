import TextField from '@mui/material/TextField'
import { FieldContainer } from 'Components'

import React, { ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type InputProps = {
  className?: string
  name: string
  placeholder?: string
  singleline?: boolean
  withoutValidate?: boolean
  validation?: Record<string, unknown>
  type?: string
  id?: string
  endAdornment?: ReactNode
  onChange?: () => void
  disabled?: boolean
  autofill?: boolean
  autoComplete?: string
  helperText?: ReactNode
  hidden?: boolean
}

const Input: React.FC<InputProps> = ({
  disabled = false,
  className,
  name,
  placeholder = 'Enter your response here',
  withoutValidate = false,
  validation,
  type,
  id,
  endAdornment = null,
  onChange,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const error = errors[name]
  const required = withoutValidate
    ? {}
    : {
        required: 'This is required',
        maxLength: {
          value: 500,
          message: 'Max length is 500 charts',
        },
        ...validation,
      }

  return (
    <FieldContainer className={className}>
      <Controller
        name={name}
        control={control}
        rules={required}
        render={({ field }) => (
          <TextField
            id={id}
            disabled={disabled}
            color="secondary"
            maxRows={4}
            fullWidth
            error={!!error}
            placeholder={placeholder}
            type={type}
            InputProps={{ endAdornment }}
            {...field}
            onChange={(...event) => {
              field.onChange(...event)
              onChange?.()
            }}
            sx={{ height: '40px' }}
          />
        )}
      />
    </FieldContainer>
  )
}

export default Input
