import { Box, Link, Typography } from '@mui/material'

const Footer = () => {
  return (
    <>
      <Box
        component={'footer'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '80px',
          padding: '0px 70px',
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '70px',
            alignItems: 'center',
            height: '80px',
            flex: 1,
          }}
        >
          <Link href={'/'} target="_blank" underline="hover">
            <Typography variant="caption">Overview</Typography>
          </Link>
          <Link href={'/'} target="_blank" underline="hover">
            <Typography variant="caption">Methodology</Typography>
          </Link>
          <Link href={'/'} target="_blank" underline="hover">
            <Typography variant="caption">Insights</Typography>
          </Link>
          <Link href={'/'} target="_blank" underline="hover">
            <Typography variant="caption">FAQs</Typography>
          </Link>
          <Link href={'/'} target="_blank" underline="hover">
            <Typography variant="caption">Support</Typography>
          </Link>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            flex: 1,
          }}
        >
          <Typography variant="caption" sx={{ opacity: '30%' }}>
            Copyright © 2023 MAHA
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default Footer
