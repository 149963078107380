import { createTheme, ThemeProvider } from '@mui/material/styles'
import * as ROUTES from 'Config/routes'
import { Route, Routes, useLocation } from 'react-router-dom'
import V2Dashboard from './dashboard/index'
import SignInV2 from './registration/sign-in'

declare module '@mui/material/styles' {
  interface Theme {
    color: {
      backgroundGradient: string
      reverseGradient: string
      white: string
      black: string
      coral: string
      green: string
      dodgerBlue: string
      opacityDodgerBlue: string
      darkBlue: string
      divider1: string
      divider2: string
      superRareJade: string
      neutral: string
      transparentWhite: string
    }
    customShadows: {
      lightShadow: string
      heavyShadow: string
    }
  }
  interface ThemeOptions {
    color?: {
      backgroundGradient?: string
      reverseGradient?: string
      white?: string
      black?: string
      coral?: string
      green?: string
      dodgerBlue?: string
      opacityDodgerBlue?: string
      darkBlue?: string
      divider1?: string
      divider2?: string
      superRareJade?: string
      neutral?: string
      transparentWhite?: string
    }
    customShadows?: {
      lightShadow?: string
      heavyShadow?: string
    }
  }
}
const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          color: '#fff',
          height: '60px',
        },
        popper: {
          backgroundColor: '#fff',
        },
        paper: {
          backgroundColor: '#fff',
        },
        groupUl: {
          backgroundColor: '#fff',
        },
        groupLabel: {
          backgroundColor: '#fff',
        },
        option: {
          color: '#000',
        },
        endAdornment: {
          button: {
            color: 'inherit',
          },
          color: '#fff',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: '#fff',
          height: '60px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#00a79c',
    },
    error: {
      main: '#da6c64',
    },
    background: {
      default: '#1E1F5F',
      paper: '#0C112A',
    },
  },
  typography: {
    fontFamily: 'Source Sans 3',
    allVariants: {
      color: '#fff',
    },
  },
  color: {
    backgroundGradient: 'linear-gradient(90deg, #141627 0%, #1B1C5B 100%)',
    reverseGradient: 'linear-gradient(90deg, #1B1C5B 0%, #141627 100%)',
    white: '#fff',
    coral: '#FE6779',
    green: '#1ADBC2',
    black: '#000',
    dodgerBlue: '#18A0FB',
    opacityDodgerBlue: 'rgba(24, 160, 251, 0)',
    darkBlue: '#15172B',
    divider1: 'rgba(153, 153, 153, 0.1)',
    divider2: 'rgba(27, 28, 87, 0.1)',
    superRareJade: '#1BB9B0',
    neutral: 'rgba(254, 103, 121, 0)',
    transparentWhite: 'rgba(255, 255, 255, 0.1)',
  },
  customShadows: {
    lightShadow: '0px 3.5px 5.5px 0px #00000005',
    heavyShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
  },
})

const V2Routes = () => {
  const location = useLocation()
  return (
    <ThemeProvider theme={theme}>
      <Routes location={location}>
        <Route path={ROUTES.V2SIGN_IN} element={<SignInV2 />} />
        <Route path={`${ROUTES.V2DASHBOARD}/*`} element={<V2Dashboard />} />
      </Routes>
    </ThemeProvider>
  )
}

export default V2Routes
