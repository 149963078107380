import { useMutation, useQuery } from '@apollo/client'
import { ErrorMessage } from '@hookform/error-message'
import { Box, Button, Typography, useTheme } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { ROLES } from 'Common/constants'

import * as ROUTES from 'Config/routes'
import { ClientContext, NavigationContext, UserInfoContext } from 'Context'
import { LOGIN } from 'Graphql/mutation'
import { GET_CURRENT_USER } from 'Graphql/query'
import { useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useGoogleAnalytics } from '../../../../hooks/useGoogleAnalytics'
import { setUserProperties } from '../../../../Utils/ga'
import { Layout } from '../components/layout'
import Input from '../components/input'
import PasswordInput from '../components/password-input'

type FormValues = {
  email: string
  password: string
  manual: string
}

const SignInV2 = () => {
  const theme = useTheme()
  const { setUserInfo } = useContext(UserInfoContext)
  const { moveForward } = useContext(NavigationContext)
  const { setClient } = useContext(ClientContext)
  const customEvent = useGoogleAnalytics()

  const navigate = useNavigate()

  const { data: { currentUser } = {} } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    setClient?.({
      clientId: currentUser?.clientId,
      clientCode: currentUser?.clientCode,
      clientName: currentUser?.clientName,
    })
  }, [currentUser, setClient])

  useEffect(() => {
    if (currentUser?.settings?.mustResetPassword) {
      navigate({
        pathname: `${ROUTES.CHANGE_PASSWORD}`,
      })
      return
    }

    if (currentUser) {
      navigate({
        pathname: `/v2${ROUTES.V2DASHBOARD}`,
      })
      return
    }
  }, [currentUser, navigate])

  useEffect(() => {
    if (!currentUser) return
    customEvent('login')
    setUserProperties({
      user_id: currentUser.emailHash,
      client: currentUser.clientCode,
    })
  }, [currentUser, customEvent])

  const [login, { error, loading }] = useMutation(LOGIN, {
    refetchQueries: [{ query: GET_CURRENT_USER }],
    onCompleted: async ({ login }) => {
      if (login) {
        const { roles, email } = login
        const ranks = roles.map(
          (role: { rank: number }) => role.rank,
        ) as number[]
        setUserInfo?.({
          email,
          role: ROLES[Math.min(...ranks) as keyof typeof ROLES],
        })
      }
    },
    onError: () => {
      setError('manual', {
        type: 'manual',
        message: 'Something went wrong. Please, try again later.',
      })
    },
  })

  const methods = useForm<FormValues>()
  const {
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
  } = methods

  const onSubmit = (values: FormValues) => {
    const { email, password } = values
    login({
      variables: { email, password },
    })
  }

  useEffect(() => {
    error &&
      setError('manual', {
        type: 'manual',
        message: error.message,
      })
  }, [error, setError])

  const onChangeHandle = () => clearErrors('manual')

  const handleClick = () => {
    const { email } = getValues()
    if (email) {
      setUserInfo?.({ email })
    }
    moveForward?.(() => {
      navigate({
        pathname: `${ROUTES.RESET_PASSWORD}`,
      })
    })
  }

  return (
    <Layout>
      {loading && (
        <Box
          sx={{
            alignItems: 'center',
            background: theme.color.white,
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            left: '0',
            opacity: '0.6',
            position: 'absolute',
            top: '0',
            width: '100%',
            zIndex: '1',
          }}
        >
          <CircularProgress
            aria-busy
            color="secondary"
            size={70}
            thickness={5}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '500px',
          height: '370px',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h4"
          color={theme.color.black}
          sx={{ fontWeight: '300' }}
        >
          Sign in to Darwin
        </Typography>
        <Typography
          variant="body1"
          color={theme.color.black}
          sx={{ fontWeight: '300' }}
        >
          Enter your email address and password below
        </Typography>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography
                variant="body1"
                color={theme.color.black}
                sx={{ fontWeight: '300' }}
              >
                Email
              </Typography>

              <Input
                name="email"
                placeholder="Username or email address"
                validation={{
                  pattern: {
                    value: /^[a-zA-Z0-9_+\-@.]+$/g,
                    message: 'Please enter either email or username',
                  },
                }}
                onChange={onChangeHandle}
              />
            </Box>
            <Box>
              <Typography
                variant="body1"
                color={theme.color.black}
                sx={{ fontWeight: '300' }}
              >
                Password
              </Typography>

              <PasswordInput
                placeholder="Password"
                name="password"
                onChange={onChangeHandle}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Typography
                variant="caption"
                component="a"
                color={theme.color.black}
                onClick={handleClick}
                sx={{ textDecoration: 'underline' }}
              >
                Forgot your password?
              </Typography>
            </Box>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              sx={{
                width: '100%',
                height: '48px',
                marginTop: '10px',
                background: theme.color.dodgerBlue,
              }}
            >
              Sign In
            </Button>
            <Typography variant="body2" color="error">
              <Box component={'span'} role="alert">
                <ErrorMessage errors={errors} name="manual" />
              </Box>
            </Typography>
          </form>
        </FormProvider>
      </Box>
    </Layout>
  )
}

export default SignInV2
