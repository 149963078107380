import { Box, useMediaQuery, useTheme } from '@mui/material'
import LeftSide from './LeftSide'

type Props = {
  children: React.ReactNode
}

export const Layout: React.FC<Props> = props => {
  const theme = useTheme()
  const matchesMd = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: matchesMd ? 'row' : 'column',
        flex: 1,
        width: '100%',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          background: theme.color.backgroundGradient,
          padding: '20px',
        }}
      >
        <LeftSide />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 3,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}
