import { useContext } from 'react'
import { TimeSeriesContext } from '../../../../../../../Context'
import { DateRangeCalendar } from '../date-range-calendar'
import { Box } from '@mui/material'

export const TimeSeriesFilters = () => {
  const { startDate, endDate, setStartDate, setEndDate } =
    useContext(TimeSeriesContext)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <DateRangeCalendar
        startDate={startDate}
        endDate={endDate}
        onDateRangeChange={(startDate, endDate) => {
          setStartDate?.(startDate)
          setEndDate?.(endDate)
        }}
      ></DateRangeCalendar>
    </Box>
  )
}
