import { Link, Typography } from '@mui/material'
import styles from './styles.module.scss'

const ErrorBoundaryFallback = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div>
          <div className={styles.balloon}>
            <Typography variant="h4">
              Something went wrong, we are sorry to see you here {';('}
            </Typography>
          </div>
        </div>
        <div>
          <Typography className={styles.p} variant="body1">
            We are sorry, but something went wrong. Please try again later or
            contact us at{' '}
            <Link color={'inherit'} href="mailto:support@maha.global">
              support@maha.global
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default ErrorBoundaryFallback
