/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Upload: { input: any; output: any }
}

export type Alert = {
  __typename?: 'Alert'
  avg_score: Scalars['Float']['output']
  daily_avg_score: Scalars['Float']['output']
  definition: Scalars['String']['output']
  difference: Scalars['Float']['output']
  stddev: Scalars['Float']['output']
  trend: Scalars['Float']['output']
  variable_id: Scalars['Int']['output']
  variable_name: Scalars['String']['output']
}

export type AnalystRating = {
  __typename?: 'AnalystRating'
  actionPt?: Maybe<Scalars['String']['output']>
  analyst?: Maybe<Scalars['String']['output']>
  analystName?: Maybe<Scalars['String']['output']>
  date: Scalars['String']['output']
  ptCurrent?: Maybe<Scalars['Float']['output']>
  ptPrior?: Maybe<Scalars['Float']['output']>
  ratingCurrent?: Maybe<Scalars['String']['output']>
  ratingPrior?: Maybe<Scalars['String']['output']>
}

export type Article = {
  __typename?: 'Article'
  amplification?: Maybe<Scalars['Float']['output']>
  author?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  domain?: Maybe<Scalars['String']['output']>
  lang?: Maybe<Scalars['String']['output']>
  media?: Maybe<Scalars['String']['output']>
  pagerank?: Maybe<Scalars['Float']['output']>
  published_on?: Maybe<Scalars['String']['output']>
  reach?: Maybe<Scalars['Int']['output']>
  sentiment?: Maybe<Scalars['String']['output']>
  sentiment_neg?: Maybe<Scalars['Float']['output']>
  sentiment_pos?: Maybe<Scalars['Float']['output']>
  summary?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type ArticlesCount = {
  __typename?: 'ArticlesCount'
  date: Scalars['String']['output']
  total: Scalars['Int']['output']
}

export type ArticlesSentiment = {
  __typename?: 'ArticlesSentiment'
  negative?: Maybe<Scalars['Float']['output']>
  neutral?: Maybe<Scalars['Float']['output']>
  positive?: Maybe<Scalars['Float']['output']>
}

export type AverageRating = {
  __typename?: 'AverageRating'
  current: Scalars['Float']['output']
  nextQuarter: Scalars['String']['output']
  predicted: Scalars['Float']['output']
  quarter: Scalars['String']['output']
  reputationAttributes: Array<Scalars['String']['output']>
}

export type BehaviorDataRecord = {
  __typename?: 'BehaviorDataRecord'
  behavior_score?: Maybe<Scalars['Float']['output']>
  community?: Maybe<Scalars['Float']['output']>
  community_development?: Maybe<Scalars['Float']['output']>
  company_id?: Maybe<Scalars['String']['output']>
  compliance_reporting?: Maybe<Scalars['Float']['output']>
  customers?: Maybe<Scalars['Float']['output']>
  dei?: Maybe<Scalars['Float']['output']>
  diversity?: Maybe<Scalars['Float']['output']>
  employee_compensation?: Maybe<Scalars['Float']['output']>
  employee_engagement?: Maybe<Scalars['Float']['output']>
  employees?: Maybe<Scalars['Float']['output']>
  environment?: Maybe<Scalars['Float']['output']>
  ethics?: Maybe<Scalars['Float']['output']>
  financial_performance?: Maybe<Scalars['Float']['output']>
  financials?: Maybe<Scalars['Float']['output']>
  governance?: Maybe<Scalars['Float']['output']>
  health_safety?: Maybe<Scalars['Float']['output']>
  human_rights_and_equality?: Maybe<Scalars['Float']['output']>
  leadership?: Maybe<Scalars['Float']['output']>
  loyalty?: Maybe<Scalars['Float']['output']>
  net_zero?: Maybe<Scalars['Float']['output']>
  products_services?: Maybe<Scalars['Float']['output']>
  quarter?: Maybe<Scalars['Int']['output']>
  reach?: Maybe<Scalars['Float']['output']>
  resource_management?: Maybe<Scalars['Float']['output']>
  supplier?: Maybe<Scalars['Float']['output']>
  vision?: Maybe<Scalars['Float']['output']>
  year?: Maybe<Scalars['Int']['output']>
}

export type BrandData = {
  __typename?: 'BrandData'
  date?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type Brands = {
  __typename?: 'Brands'
  company?: Maybe<Scalars['String']['output']>
  data?: Maybe<Array<Maybe<BrandData>>>
}

export type CategorizedArticleJob = {
  __typename?: 'CategorizedArticleJob'
  createdAt?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['String']['output']>
  jobId: Scalars['String']['output']
  jobsLeft?: Maybe<Scalars['Int']['output']>
  rate?: Maybe<Scalars['Float']['output']>
  result?: Maybe<Scalars['String']['output']>
  startDate?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  tag?: Maybe<Scalars['String']['output']>
}

export type ChangeVariablesResponse = {
  __typename?: 'ChangeVariablesResponse'
  code: Scalars['String']['output']
  data?: Maybe<Array<Maybe<PurposeSignatureDate>>>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type Chat = {
  __typename?: 'Chat'
  createdAt: Scalars['String']['output']
  id: Scalars['ID']['output']
  messages?: Maybe<Array<Maybe<Message>>>
  userId: Scalars['ID']['output']
}

export type Client = {
  __typename?: 'Client'
  code?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ClientSubscription = {
  __typename?: 'ClientSubscription'
  client: Client
  createdAt?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['String']['output']>
  features?: Maybe<Array<Maybe<PaidFeature>>>
  id: Scalars['ID']['output']
  startDate?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type Company = {
  __typename?: 'Company'
  clientCode?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type CompanyGain = {
  __typename?: 'CompanyGain'
  companyId: Scalars['ID']['output']
  companyName: Scalars['String']['output']
  differenceVsPrevious?: Maybe<Scalars['Float']['output']>
  score?: Maybe<Scalars['Float']['output']>
}

export enum ContentType {
  Chart = 'chart',
  File = 'file',
  Image = 'image',
  Markdown = 'markdown',
  Text = 'text',
}

export type Customer = {
  __typename?: 'Customer'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type CustomerReport = {
  __typename?: 'CustomerReport'
  createdAt?: Maybe<Scalars['String']['output']>
  customer?: Maybe<Customer>
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type DataForSignatureMatch = {
  __typename?: 'DataForSignatureMatch'
  company?: Maybe<Scalars['Float']['output']>
  name?: Maybe<Scalars['String']['output']>
  original?: Maybe<Scalars['Float']['output']>
  originalVsCompany?: Maybe<Scalars['Float']['output']>
  scoreVsCompany?: Maybe<Scalars['Float']['output']>
  y?: Maybe<Scalars['Float']['output']>
}

export type EmailWhitelist = {
  __typename?: 'EmailWhitelist'
  clientId: Scalars['ID']['output']
  emailOrDomain: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type ExecutiveSummarySentimentScore = {
  __typename?: 'ExecutiveSummarySentimentScore'
  companyId: Scalars['ID']['output']
  companyName: Scalars['String']['output']
  score?: Maybe<Scalars['Float']['output']>
}

export type ExploreCompanyData = {
  __typename?: 'ExploreCompanyData'
  average?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  categories?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  data?: Maybe<Array<Maybe<ExploreCompanyDataSlice>>>
}

export type ExploreCompanyDataSlice = {
  __typename?: 'ExploreCompanyDataSlice'
  color?: Maybe<Scalars['String']['output']>
  data?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  name?: Maybe<Scalars['String']['output']>
}

export type FileUploadResponse = {
  __typename?: 'FileUploadResponse'
  code: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  result?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  success: Scalars['Boolean']['output']
}

export type FinancialRating = {
  __typename?: 'FinancialRating'
  averageRating?: Maybe<Scalars['Float']['output']>
  buy?: Maybe<Scalars['Float']['output']>
  hold?: Maybe<Scalars['Float']['output']>
  month: Scalars['String']['output']
  overperform?: Maybe<Scalars['Float']['output']>
  sell?: Maybe<Scalars['Float']['output']>
  underperform?: Maybe<Scalars['Float']['output']>
}

export type Industry = {
  __typename?: 'Industry'
  forClient?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Influencer = {
  __typename?: 'Influencer'
  domain: Scalars['String']['output']
  published_on: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type Influencers = {
  __typename?: 'Influencers'
  values?: Maybe<Array<Influencer>>
}

export type Insight = {
  __typename?: 'Insight'
  companyId: Scalars['Int']['output']
  summary: Scalars['String']['output']
}

export type KeyOpportunity = {
  __typename?: 'KeyOpportunity'
  value: Array<Scalars['Float']['output']>
}

export type Mention = {
  __typename?: 'Mention'
  company: Scalars['String']['output']
  companyId: Scalars['String']['output']
  dataType: RefinitivDataType
  mentions: Scalars['Int']['output']
  timestamp: Scalars['String']['output']
}

export type Mentions = {
  __typename?: 'Mentions'
  companyId: Scalars['ID']['output']
  companyName: Scalars['String']['output']
  count?: Maybe<Scalars['Int']['output']>
}

export type Message = {
  __typename?: 'Message'
  chatId: Scalars['ID']['output']
  content: Scalars['String']['output']
  contentType?: Maybe<ContentType>
  createdAt: Scalars['String']['output']
  id: Scalars['ID']['output']
  senderType: Scalars['String']['output']
}

export type MessageInput = {
  content: Scalars['String']['input']
  senderId: Scalars['ID']['input']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Add email to client's whitelist */
  addEmailToWhitelist: MutationResponse
  /** Adds a new subscription */
  addSubscription?: Maybe<ClientSubscription>
  calculateBehaviorData: Array<PullBehaviorDataJob>
  cancelCategorizationJobs: Scalars['Boolean']['output']
  /** Change logged-in user password. */
  changePassword: MutationResponse
  /** Change user password. New password cannot be the same as an existing one. */
  changeUserPassword: MutationResponse
  /** Change Variable score */
  changeVariableScore?: Maybe<ChangeVariablesResponse>
  createChat?: Maybe<Chat>
  createMessage?: Maybe<Message>
  /** Creates a new user */
  createUser: UserMutationResponse
  deleteChat?: Maybe<Scalars['Boolean']['output']>
  /** Deletes a customer report */
  deleteCustomerReport: ReportCommandResult
  /** Removes user by user's email */
  deleteUser: UserMutationResponse
  /** Updates an existing user w/ new info */
  editUser: UserMutationResponse
  /** Sign in user */
  login: User
  /** Sign out current user */
  logout: Scalars['Boolean']['output']
  markNotificationAsRead: Scalars['Boolean']['output']
  pullArticles: Array<PullOnclusiveArticlesJob>
  pullCategorizedArticles: Array<PullCategorizedArticlesJob>
  pullCompanyExplorerData: Array<PullCompanyExplorerDataJob>
  pullInsights: Array<PullInsightsJob>
  pullRefinitivData: PullRefinitivDataJob
  pullSentimentHistoryData: Array<PullSentimentHistoryDataJob>
  /** Reset Variable score */
  resetVariableScore?: Maybe<ChangeVariablesResponse>
  /** Send email containing link to reset user's password */
  sendResetPasswordEmail: MutationResponse
  /**
   * Update user information.
   * This mutation should be invoked by the user as part of sign-up process.
   */
  updateUserInfo: MutationResponse
  /** Uploads customer report data */
  uploadCustomerReportFile: ReportCommandResult
  /** Uploads users roster data */
  uploadUsersRosterFile: FileUploadResponse
  /** Verify reset password token */
  verifyResetPasswordToken: VerifyTokenResponse
}

export type MutationAddEmailToWhitelistArgs = {
  clientId: Scalars['ID']['input']
  emailOrDomain: Scalars['String']['input']
}

export type MutationAddSubscriptionArgs = {
  clientId: Scalars['ID']['input']
  endDate: Scalars['String']['input']
  features: Array<PaidFeature>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type MutationCalculateBehaviorDataArgs = {
  end?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['String']['input']>
}

export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input']
}

export type MutationChangeUserPasswordArgs = {
  newPassword: Scalars['String']['input']
  token: Scalars['String']['input']
  userId: Scalars['ID']['input']
}

export type MutationChangeVariableScoreArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  groupId: Scalars['ID']['input']
  value?: InputMaybe<Scalars['Float']['input']>
  variableName: Scalars['String']['input']
}

export type MutationCreateMessageArgs = {
  chatId: Scalars['ID']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  input: Scalars['String']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateUserArgs = {
  input: UserInput
}

export type MutationDeleteChatArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCustomerReportArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteUserArgs = {
  email: Scalars['String']['input']
}

export type MutationEditUserArgs = {
  input: UserInput
}

export type MutationLoginArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationMarkNotificationAsReadArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationPullArticlesArgs = {
  endDate: Scalars['String']['input']
  startDate: Scalars['String']['input']
}

export type MutationPullCategorizedArticlesArgs = {
  endDate: Scalars['String']['input']
  limit: Scalars['Int']['input']
  startDate: Scalars['String']['input']
}

export type MutationPullCompanyExplorerDataArgs = {
  date: Scalars['String']['input']
}

export type MutationPullInsightsArgs = {
  endDate: Scalars['String']['input']
  startDate: Scalars['String']['input']
}

export type MutationPullRefinitivDataArgs = {
  end?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['String']['input']>
}

export type MutationPullSentimentHistoryDataArgs = {
  end?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['String']['input']>
}

export type MutationResetVariableScoreArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  groupId: Scalars['ID']['input']
}

export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String']['input']
  redirectTo: Scalars['String']['input']
}

export type MutationUpdateUserInfoArgs = {
  jobTitle: Scalars['String']['input']
  teamId: Scalars['ID']['input']
}

export type MutationUploadCustomerReportFileArgs = {
  clientId: Scalars['ID']['input']
  file: Scalars['Upload']['input']
}

export type MutationUploadUsersRosterFileArgs = {
  clientId: Scalars['ID']['input']
  file: Scalars['Upload']['input']
}

export type MutationVerifyResetPasswordTokenArgs = {
  token: Scalars['String']['input']
  userId: Scalars['ID']['input']
}

export type MutationResponse = {
  __typename?: 'MutationResponse'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type Notification = {
  __typename?: 'Notification'
  createdAt: Scalars['String']['output']
  id: Scalars['Int']['output']
  message: Scalars['String']['output']
  read: Scalars['Boolean']['output']
}

export enum PaidFeature {
  Onclusive = 'ONCLUSIVE',
}

export type Perception = {
  __typename?: 'Perception'
  averageScore?: Maybe<Scalars['Float']['output']>
  dataType: RefinitivDataType
  score?: Maybe<Scalars['Float']['output']>
  variableId: Scalars['ID']['output']
  variableName: Scalars['String']['output']
}

export type PerceptionRealityGap = {
  __typename?: 'PerceptionRealityGap'
  perception?: Maybe<Array<Maybe<Perception>>>
  signature?: Maybe<Array<Maybe<Signature>>>
}

export type PerceptionRealityGapScore = {
  __typename?: 'PerceptionRealityGapScore'
  averageScore?: Maybe<Scalars['Float']['output']>
  dataType: RefinitivDataType
  leaderScore?: Maybe<Scalars['Float']['output']>
  score?: Maybe<Scalars['Float']['output']>
}

export type PillarReputationScore = {
  __typename?: 'PillarReputationScore'
  value: ScoresValue
}

export type PullBehaviorDataJob = {
  __typename?: 'PullBehaviorDataJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PullCategorizedArticlesJob = {
  __typename?: 'PullCategorizedArticlesJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PullCompanyExplorerDataJob = {
  __typename?: 'PullCompanyExplorerDataJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PullInsightsJob = {
  __typename?: 'PullInsightsJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PullOnclusiveArticlesJob = {
  __typename?: 'PullOnclusiveArticlesJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PullRefinitivDataJob = {
  __typename?: 'PullRefinitivDataJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PullSentimentHistoryDataJob = {
  __typename?: 'PullSentimentHistoryDataJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PurposeDriver = {
  __typename?: 'PurposeDriver'
  color?: Maybe<Scalars['String']['output']>
  compareVsPeriod?: Maybe<Scalars['String']['output']>
  differenceWithPastQuarter?: Maybe<Scalars['Float']['output']>
  name?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type PurposePillar = {
  __typename?: 'PurposePillar'
  color?: Maybe<Scalars['String']['output']>
  compareVs?: Maybe<Scalars['String']['output']>
  drilldown?: Maybe<Array<Maybe<SignatureVariable>>>
  name?: Maybe<Scalars['String']['output']>
  optimum?: Maybe<Scalars['Float']['output']>
  optimumDrilldown?: Maybe<Array<Maybe<SignatureVariable>>>
  previousValue?: Maybe<Scalars['Float']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type PurposeSignature = {
  __typename?: 'PurposeSignature'
  company?: Maybe<Array<Maybe<PurposeSignatureDate>>>
  data?: Maybe<Array<Maybe<PurposeSignatureDate>>>
  dataForSignatureMatch?: Maybe<Array<Maybe<DataForSignatureMatch>>>
  optimum?: Maybe<Array<Maybe<PurposeSignatureDate>>>
}

export type PurposeSignatureDate = {
  __typename?: 'PurposeSignatureDate'
  current?: Maybe<Scalars['Float']['output']>
  name?: Maybe<Scalars['String']['output']>
  score?: Maybe<Scalars['Float']['output']>
}

export type Query = {
  __typename?: 'Query'
  alerts?: Maybe<Array<Alert>>
  analystRatings: Array<AnalystRating>
  /** Get the list of articles for client */
  articles: Array<Article>
  /** Get the list of articles for company */
  articlesByCompany: Array<Article>
  /** Get the list of articles for every pillar for client */
  articlesByPillars: Array<Article>
  /** Get the list of articles for Trending Topics for client */
  articlesByTrendingTopics: Array<Article>
  /** Get the list of all articles count for client */
  articlesCount: Array<ArticlesCount>
  /** Get the sentiment distribution for client (positive, negative, neutral) */
  articlesSentiment: ArticlesSentiment
  /** Get the list of articles for client for positive, negative and overall sentiment */
  articlesSentimentOvertime: Array<Article>
  averageRatings: AverageRating
  behaviorData: Array<BehaviorDataRecord>
  categorizedArticlesJobs?: Maybe<Array<CategorizedArticleJob>>
  chat?: Maybe<Chat>
  chats?: Maybe<Array<Maybe<Chat>>>
  /** Check if a client has active subscription */
  checkActiveSubscription: Scalars['Boolean']['output']
  /** Check if user's email is in whitelist for selected client */
  checkIfEmailWhitelisted?: Maybe<Scalars['Boolean']['output']>
  /** Get the list of all clients */
  clients: Array<Client>
  /** companies for Purpose Signature Overview chart */
  companiesInPurposeSignatureChart?: Maybe<Array<Maybe<Company>>>
  /** Company vs. Top-Most Purpose Driven companies */
  companyVsTopMostPurposeDriven?: Maybe<Array<Maybe<PurposeDriver>>>
  /** Get current authenticated user from cookies */
  currentUser?: Maybe<User>
  /** Returns all customer reports */
  customerReports: Array<CustomerReport>
  customerReportsByClient: Array<CustomerReport>
  dailyMentions?: Maybe<Array<Maybe<Mention>>>
  downloadPerceptionRealityGapData?: Maybe<Scalars['String']['output']>
  /** Get email whitelist */
  emailWhitelist: Array<EmailWhitelist>
  /** Explore company data by industry */
  exploreDataByIndustry?: Maybe<ExploreCompanyData>
  /** Explore company data by year */
  exploreDataByYear?: Maybe<ExploreCompanyData>
  /** High Risk areas */
  highRiskAreas?: Maybe<Array<Maybe<PurposePillar>>>
  /** Fetch industries */
  industries: Array<Industry>
  insights: Insight
  marketShare: ReputationScoreV2
  mediaMentions: ReputationScoreV2
  messages?: Maybe<Array<Maybe<Message>>>
  notifications: Array<Notification>
  perceptionRealityGap?: Maybe<PerceptionRealityGap>
  perceptionRealityGapScore?: Maybe<Array<Maybe<PerceptionRealityGapScore>>>
  pillarReputationChangesScore: PillarReputationScore
  pillarReputationScore: PillarReputationScore
  pullArticlesJobStatus: Array<PullOnclusiveArticlesJob>
  pullCalculateBehaviorDataJobStatus: Array<PullBehaviorDataJob>
  pullCategorizedArticlesJobStatus: Array<PullCategorizedArticlesJob>
  pullCompanyExplorerDataJobStatus: Array<PullCompanyExplorerDataJob>
  pullInsightsJobStatus: Array<PullInsightsJob>
  pullRefinitivDataJobStatus: PullRefinitivDataJob
  pullSentimentHistoryDataJobStatus: Array<PullSentimentHistoryDataJob>
  /** Purpose drivers data */
  purposeDriversSignature?: Maybe<Array<Maybe<PurposeDriver>>>
  /** Get purpose match value */
  purposeMatch?: Maybe<Scalars['Float']['output']>
  /** Purpose pillars */
  purposePillars?: Maybe<Array<Maybe<PurposePillar>>>
  /** data for Purpose Signature Overview chart */
  purposeSignature?: Maybe<PurposeSignature>
  rankedSentimentDrivers: Array<SentimentDriver>
  ratings: Array<FinancialRating>
  reputationInfluencers: Influencers
  /** Reputation score */
  reputationScore?: Maybe<ReputationScore>
  reputationScoreChangeV2: ReputationScoreV2
  reputationScoreDriverV2: ReputationScoreV2
  reputationScorePotential: ReputationScoreV2
  reputationScorePotentialKeyOpportunity: KeyOpportunity
  reputationScoreV2: ReputationScoreV2
  scoresRelevanceGraph?: Maybe<Array<Brands>>
  scoresSentimentGraph?: Maybe<Array<Brands>>
  sentimentByPillar?: Maybe<Array<Maybe<SentimentData>>>
  sentimentBySignature?: Maybe<Array<Maybe<SentimentData>>>
  sentimentData: Array<SentimentDataRecord>
  sentimentExecutiveSummary?: Maybe<SentimentExecutiveSummary>
  sentimentOvertimeScore?: Maybe<SentimentOverTime>
  sentimentScore?: Maybe<Array<Maybe<SentimentScore>>>
  /** data for Purpose Signature Simulator */
  sessionPurposeSignature?: Maybe<PurposeSignature>
  /** Fet the share voice distribution for client */
  shareVoice: Array<ShareVoice>
  signatureExecutiveSummary?: Maybe<SignatureExecutiveSummary>
  /** Signature match */
  signatureMatch?: Maybe<Array<Maybe<PurposePillar>>>
  stockClosePriceOvertime: Array<StockClosePrice>
  /** Get list of all subscriptions */
  subscriptions: Array<ClientSubscription>
  /** data for Team Purpose Signature Overview chart */
  teamPurposeSignature?: Maybe<PurposeSignature>
  trafficValue: ReputationScoreV2
  trendingTopics?: Maybe<Array<Maybe<TrendingTopic>>>
  /** Get all users information */
  userDetails: Array<UserDetail>
  /** Variable Group (ESG, HR, CFO, DEI) */
  variableGroups?: Maybe<Array<Maybe<VariableGroup>>>
  variableOvertimeScore?: Maybe<Array<Maybe<VariableOverTimeScore>>>
  /** Years for Purpose Signature Overview chart */
  yearsInPurposeSignatureChart?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
}

export type QueryAlertsArgs = {
  clientId: Scalars['ID']['input']
  endDate: Scalars['String']['input']
  startDate: Scalars['String']['input']
}

export type QueryAnalystRatingsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryArticlesArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryArticlesByCompanyArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryArticlesByPillarsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  variableId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryArticlesByTrendingTopicsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  variableId: Scalars['ID']['input']
}

export type QueryArticlesCountArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryArticlesSentimentArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryArticlesSentimentOvertimeArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  sentiment: Sentiment
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryAverageRatingsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryBehaviorDataArgs = {
  clientId: Scalars['ID']['input']
  end?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['String']['input']>
}

export type QueryChatArgs = {
  id: Scalars['ID']['input']
}

export type QueryCheckActiveSubscriptionArgs = {
  features: Array<PaidFeature>
}

export type QueryCheckIfEmailWhitelistedArgs = {
  client: Scalars['String']['input']
  email: Scalars['String']['input']
}

export type QueryCompaniesInPurposeSignatureChartArgs = {
  clientId: Scalars['ID']['input']
}

export type QueryCompanyVsTopMostPurposeDrivenArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryCustomerReportsByClientArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryDailyMentionsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryDownloadPerceptionRealityGapDataArgs = {
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryEmailWhitelistArgs = {
  clientId: Scalars['ID']['input']
}

export type QueryExploreDataByIndustryArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryExploreDataByYearArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryHighRiskAreasArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryIndustriesArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryInsightsArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryMarketShareArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryMediaMentionsArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryMessagesArgs = {
  chatId: Scalars['ID']['input']
}

export type QueryPerceptionRealityGapArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryPerceptionRealityGapScoreArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryPillarReputationChangesScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPillarReputationScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPullArticlesJobStatusArgs = {
  jobs: Array<Scalars['String']['input']>
}

export type QueryPullCalculateBehaviorDataJobStatusArgs = {
  jobs: Array<Scalars['String']['input']>
}

export type QueryPullCategorizedArticlesJobStatusArgs = {
  jobs: Array<Scalars['String']['input']>
}

export type QueryPullCompanyExplorerDataJobStatusArgs = {
  jobs: Array<Scalars['String']['input']>
}

export type QueryPullInsightsJobStatusArgs = {
  jobs: Array<Scalars['String']['input']>
}

export type QueryPullRefinitivDataJobStatusArgs = {
  jobId?: InputMaybe<Scalars['String']['input']>
}

export type QueryPullSentimentHistoryDataJobStatusArgs = {
  jobs: Array<Scalars['String']['input']>
}

export type QueryPurposeDriversSignatureArgs = {
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryPurposeMatchArgs = {
  clientId: Scalars['ID']['input']
}

export type QueryPurposePillarsArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryPurposeSignatureArgs = {
  companyId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  year: Scalars['Int']['input']
}

export type QueryRankedSentimentDriversArgs = {
  industryId: Scalars['ID']['input']
}

export type QueryRatingsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryReputationInfluencersArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryReputationScoreArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryReputationScoreChangeV2Args = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryReputationScoreDriverV2Args = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryReputationScorePotentialArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryReputationScorePotentialKeyOpportunityArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryReputationScoreV2Args = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryScoresRelevanceGraphArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryScoresSentimentGraphArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentByPillarArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentBySignatureArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentDataArgs = {
  clientId: Scalars['ID']['input']
  end?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentExecutiveSummaryArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId?: InputMaybe<Scalars['ID']['input']>
  source?: InputMaybe<RefinitivDataSource>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentOvertimeScoreArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentScoreArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySessionPurposeSignatureArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  companyId?: InputMaybe<Scalars['ID']['input']>
  groupId?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  restoreUserSession?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryShareVoiceArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySignatureExecutiveSummaryArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QuerySignatureMatchArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryStockClosePriceOvertimeArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryTeamPurposeSignatureArgs = {
  companyId: Scalars['ID']['input']
  groupId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  year: Scalars['Int']['input']
}

export type QueryTrafficValueArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryTrendingTopicsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryUserDetailsArgs = {
  clientId: Scalars['ID']['input']
  teamsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type QueryVariableOvertimeScoreArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export enum RefinitivDataSource {
  News = 'News',
  NewsSocial = 'News_Social',
  Social = 'Social',
}

export enum RefinitivDataType {
  News = 'News',
  NewsSocial = 'News_Social',
  Social = 'Social',
}

export type ReportCommandResult = {
  __typename?: 'ReportCommandResult'
  code: Scalars['Int']['output']
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type ReputationDriver = {
  __typename?: 'ReputationDriver'
  variableId: Scalars['ID']['output']
  variableName: Scalars['String']['output']
  weight?: Maybe<Scalars['Float']['output']>
}

export type ReputationScore = {
  __typename?: 'ReputationScore'
  averageScore?: Maybe<Scalars['Float']['output']>
  compareVsPeriod?: Maybe<Scalars['String']['output']>
  difference?: Maybe<Scalars['Float']['output']>
  leaderScore?: Maybe<Scalars['Float']['output']>
  score: Scalars['Float']['output']
}

export type ReputationScoreV2 = {
  __typename?: 'ReputationScoreV2'
  value: Scalars['Float']['output']
}

export type Role = {
  __typename?: 'Role'
  name: Scalars['String']['output']
  rank: Scalars['Int']['output']
}

export type ScoresValue = {
  __typename?: 'ScoresValue'
  Community?: Maybe<Scalars['Float']['output']>
  Employees?: Maybe<Scalars['Float']['output']>
  Environment?: Maybe<Scalars['Float']['output']>
  Financials?: Maybe<Scalars['Float']['output']>
}

export enum Sentiment {
  Negative = 'negative',
  Neutral = 'neutral',
  Positive = 'positive',
}

export type SentimentData = {
  __typename?: 'SentimentData'
  averageScore?: Maybe<Scalars['Float']['output']>
  dataType: RefinitivDataType
  leaderScore?: Maybe<Scalars['Float']['output']>
  score?: Maybe<Scalars['Float']['output']>
  variableId: Scalars['ID']['output']
  variableName: Scalars['String']['output']
}

export type SentimentDataRecord = {
  __typename?: 'SentimentDataRecord'
  community?: Maybe<Scalars['Float']['output']>
  community_development?: Maybe<Scalars['Float']['output']>
  company_id?: Maybe<Scalars['String']['output']>
  compliance_reporting?: Maybe<Scalars['Float']['output']>
  customers?: Maybe<Scalars['Float']['output']>
  dei?: Maybe<Scalars['Float']['output']>
  diversity?: Maybe<Scalars['Float']['output']>
  employee_compensation?: Maybe<Scalars['Float']['output']>
  employee_engagement?: Maybe<Scalars['Float']['output']>
  employees?: Maybe<Scalars['Float']['output']>
  environment?: Maybe<Scalars['Float']['output']>
  ethics?: Maybe<Scalars['Float']['output']>
  financial_performance?: Maybe<Scalars['Float']['output']>
  financials?: Maybe<Scalars['Float']['output']>
  governance?: Maybe<Scalars['Float']['output']>
  health_safety?: Maybe<Scalars['Float']['output']>
  human_rights_and_equality?: Maybe<Scalars['Float']['output']>
  leadership?: Maybe<Scalars['Float']['output']>
  loyalty?: Maybe<Scalars['Float']['output']>
  month?: Maybe<Scalars['Int']['output']>
  net_zero?: Maybe<Scalars['Float']['output']>
  products_services?: Maybe<Scalars['Float']['output']>
  reach?: Maybe<Scalars['Float']['output']>
  resource_management?: Maybe<Scalars['Float']['output']>
  sentiment_score?: Maybe<Scalars['Float']['output']>
  supplier?: Maybe<Scalars['Float']['output']>
  vision?: Maybe<Scalars['Float']['output']>
  year?: Maybe<Scalars['Int']['output']>
}

export type SentimentDriver = {
  __typename?: 'SentimentDriver'
  pillarName: Scalars['String']['output']
  variableId: Scalars['ID']['output']
  variableName: Scalars['String']['output']
}

export type SentimentExecutiveSummary = {
  __typename?: 'SentimentExecutiveSummary'
  mentions?: Maybe<Array<Maybe<Mentions>>>
  score?: Maybe<Array<Maybe<ExecutiveSummarySentimentScore>>>
}

export type SentimentOverTime = {
  __typename?: 'SentimentOverTime'
  negativeSentiment?: Maybe<Array<Maybe<SentimentScore>>>
  overallSentiment?: Maybe<Array<Maybe<SentimentScore>>>
  positiveSentiment?: Maybe<Array<Maybe<SentimentScore>>>
}

export type SentimentScore = {
  __typename?: 'SentimentScore'
  averageScore?: Maybe<Scalars['Float']['output']>
  dataType: RefinitivDataType
  date?: Maybe<Scalars['String']['output']>
  leaderScore?: Maybe<Scalars['Float']['output']>
  score?: Maybe<Scalars['Float']['output']>
}

export type ShareVoice = {
  __typename?: 'ShareVoice'
  company: Scalars['String']['output']
  share?: Maybe<Scalars['Int']['output']>
}

export type Signature = {
  __typename?: 'Signature'
  name?: Maybe<Scalars['String']['output']>
  optimum?: Maybe<Scalars['Float']['output']>
  pillar?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type SignatureExecutiveSummary = {
  __typename?: 'SignatureExecutiveSummary'
  competitorsGains?: Maybe<Array<Maybe<CompanyGain>>>
  drivers?: Maybe<Array<Maybe<ReputationDriver>>>
}

export type SignatureVariable = {
  __typename?: 'SignatureVariable'
  compareVs?: Maybe<Scalars['String']['output']>
  drilldown?: Maybe<Array<Maybe<Variable>>>
  name?: Maybe<Scalars['String']['output']>
  optimumDrilldown?: Maybe<Array<Maybe<Variable>>>
  previousValue?: Maybe<Scalars['Float']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type StockClosePrice = {
  __typename?: 'StockClosePrice'
  adjustedClosingPrice?: Maybe<Scalars['Float']['output']>
  updatedAt: Scalars['String']['output']
}

export type Team = {
  __typename?: 'Team'
  childTeams?: Maybe<Array<Maybe<Team>>>
  client?: Maybe<Client>
  hideChildren?: Maybe<Scalars['Boolean']['output']>
  id?: Maybe<Scalars['ID']['output']>
  isDepartment?: Maybe<Scalars['Boolean']['output']>
  name?: Maybe<Scalars['String']['output']>
  parentTeamId?: Maybe<Scalars['ID']['output']>
}

export type TrendingTopic = {
  __typename?: 'TrendingTopic'
  averageScore?: Maybe<Scalars['Float']['output']>
  dataType: RefinitivDataType
  pillarId?: Maybe<Scalars['ID']['output']>
  pillarName?: Maybe<Scalars['String']['output']>
  score?: Maybe<Scalars['Float']['output']>
  variableId: Scalars['ID']['output']
  variableName: Scalars['String']['output']
}

export type User = {
  __typename?: 'User'
  clientCode?: Maybe<Scalars['String']['output']>
  clientId?: Maybe<Scalars['ID']['output']>
  clientName?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  emailHash: Scalars['String']['output']
  id: Scalars['ID']['output']
  respondentId?: Maybe<Scalars['ID']['output']>
  respondentUuid?: Maybe<Scalars['String']['output']>
  roles: Array<Role>
  settings?: Maybe<UserSettings>
}

export type UserDetail = {
  __typename?: 'UserDetail'
  accountStatus: Scalars['String']['output']
  email: Scalars['String']['output']
  id?: Maybe<Scalars['ID']['output']>
  jobTitle?: Maybe<Scalars['String']['output']>
  role?: Maybe<Role>
  roleId?: Maybe<Scalars['ID']['output']>
}

export type UserInput = {
  accountStatus?: InputMaybe<Scalars['String']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  email: Scalars['String']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  mustResetPassword?: InputMaybe<Scalars['Boolean']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  roleId: Scalars['ID']['input']
}

export type UserMutationResponse = {
  __typename?: 'UserMutationResponse'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  user?: Maybe<UserDetail>
}

export type UserSettings = {
  __typename?: 'UserSettings'
  mustResetPassword?: Maybe<Scalars['Boolean']['output']>
}

export type Variable = {
  __typename?: 'Variable'
  compareVs?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  previousValue?: Maybe<Scalars['Float']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type VariableGroup = {
  __typename?: 'VariableGroup'
  id?: Maybe<Scalars['ID']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type VariableOverTimeScore = {
  __typename?: 'VariableOverTimeScore'
  dataType: RefinitivDataType
  date?: Maybe<Scalars['String']['output']>
  pillarName?: Maybe<Scalars['String']['output']>
  score?: Maybe<Scalars['Float']['output']>
  variableName?: Maybe<Scalars['String']['output']>
  variableWeight?: Maybe<Scalars['Float']['output']>
}

export type VerifyTokenResponse = {
  __typename?: 'VerifyTokenResponse'
  code: Scalars['String']['output']
  email?: Maybe<Scalars['String']['output']>
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login: {
    __typename?: 'User'
    email: string
    respondentUuid?: string | null
    clientName?: string | null
    clientCode?: string | null
    clientId?: string | null
    roles: Array<{ __typename?: 'Role'; name: string; rank: number }>
  }
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean }

export type CreateUserMutationVariables = Exact<{
  input: UserInput
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  createUser: {
    __typename?: 'UserMutationResponse'
    code: string
    success: boolean
    message: string
    user?: {
      __typename?: 'UserDetail'
      id?: string | null
      email: string
      roleId?: string | null
      role?: { __typename?: 'Role'; name: string; rank: number } | null
    } | null
  }
}

export type DeleteUserMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type DeleteUserMutation = {
  __typename?: 'Mutation'
  deleteUser: {
    __typename?: 'UserMutationResponse'
    code: string
    success: boolean
    message: string
    user?: { __typename?: 'UserDetail'; email: string } | null
  }
}

export type EditUserMutationVariables = Exact<{
  input: UserInput
}>

export type EditUserMutation = {
  __typename?: 'Mutation'
  editUser: {
    __typename?: 'UserMutationResponse'
    code: string
    success: boolean
    message: string
    user?: {
      __typename?: 'UserDetail'
      id?: string | null
      email: string
      roleId?: string | null
      accountStatus: string
      role?: { __typename?: 'Role'; name: string; rank: number } | null
    } | null
  }
}

export type UploadUsersRosterFileMutationVariables = Exact<{
  file: Scalars['Upload']['input']
  clientId: Scalars['ID']['input']
}>

export type UploadUsersRosterFileMutation = {
  __typename?: 'Mutation'
  uploadUsersRosterFile: {
    __typename?: 'FileUploadResponse'
    code: string
    success: boolean
    message?: string | null
    result?: Array<string | null> | null
  }
}

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars['String']['input']
  redirectTo: Scalars['String']['input']
}>

export type SendResetPasswordEmailMutation = {
  __typename?: 'Mutation'
  sendResetPasswordEmail: {
    __typename?: 'MutationResponse'
    code: string
    success: boolean
    message: string
  }
}

export type VerifyResetPasswordTokenMutationVariables = Exact<{
  userId: Scalars['ID']['input']
  token: Scalars['String']['input']
}>

export type VerifyResetPasswordTokenMutation = {
  __typename?: 'Mutation'
  verifyResetPasswordToken: {
    __typename?: 'VerifyTokenResponse'
    code: string
    success: boolean
    message?: string | null
    email?: string | null
  }
}

export type ChangeUserPasswordMutationVariables = Exact<{
  token: Scalars['String']['input']
  userId: Scalars['ID']['input']
  newPassword: Scalars['String']['input']
}>

export type ChangeUserPasswordMutation = {
  __typename?: 'Mutation'
  changeUserPassword: {
    __typename?: 'MutationResponse'
    code: string
    success: boolean
    message: string
  }
}

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input']
}>

export type ChangePasswordMutation = {
  __typename?: 'Mutation'
  changePassword: {
    __typename?: 'MutationResponse'
    code: string
    success: boolean
    message: string
  }
}

export type AddEmailToWhitelistMutationVariables = Exact<{
  clientId: Scalars['ID']['input']
  emailOrDomain: Scalars['String']['input']
}>

export type AddEmailToWhitelistMutation = {
  __typename?: 'Mutation'
  addEmailToWhitelist: {
    __typename?: 'MutationResponse'
    code: string
    success: boolean
    message: string
  }
}

export type ChangeVariableScoreMutationVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  groupId: Scalars['ID']['input']
  variableName: Scalars['String']['input']
  value?: InputMaybe<Scalars['Float']['input']>
}>

export type ChangeVariableScoreMutation = {
  __typename?: 'Mutation'
  changeVariableScore?: {
    __typename?: 'ChangeVariablesResponse'
    code: string
    success: boolean
    message: string
    data?: Array<{
      __typename?: 'PurposeSignatureDate'
      score?: number | null
      name?: string | null
      current?: number | null
    } | null> | null
  } | null
}

export type ResetVariableScoreMutationVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  groupId: Scalars['ID']['input']
}>

export type ResetVariableScoreMutation = {
  __typename?: 'Mutation'
  resetVariableScore?: {
    __typename?: 'ChangeVariablesResponse'
    code: string
    success: boolean
    message: string
    data?: Array<{
      __typename?: 'PurposeSignatureDate'
      score?: number | null
      name?: string | null
      current?: number | null
    } | null> | null
  } | null
}

export type PullRefinitivDataMutationVariables = Exact<{
  start?: InputMaybe<Scalars['String']['input']>
  end?: InputMaybe<Scalars['String']['input']>
}>

export type PullRefinitivDataMutation = {
  __typename?: 'Mutation'
  pullRefinitivData: {
    __typename?: 'PullRefinitivDataJob'
    status?: string | null
    message?: string | null
    jobId: string
  }
}

export type CreateChatMutationVariables = Exact<{ [key: string]: never }>

export type CreateChatMutation = {
  __typename?: 'Mutation'
  createChat?: { __typename?: 'Chat'; id: string } | null
}

export type CreateMessageMutationVariables = Exact<{
  chatId: Scalars['ID']['input']
  input: Scalars['String']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type CreateMessageMutation = {
  __typename?: 'Mutation'
  createMessage?: {
    __typename?: 'Message'
    id: string
    content: string
    contentType?: ContentType | null
  } | null
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'User'
    respondentUuid?: string | null
    clientName?: string | null
    clientCode?: string | null
    clientId?: string | null
    email: string
    emailHash: string
    roles: Array<{ __typename?: 'Role'; name: string; rank: number }>
    settings?: {
      __typename?: 'UserSettings'
      mustResetPassword?: boolean | null
    } | null
  } | null
}

export type ClientsQueryVariables = Exact<{ [key: string]: never }>

export type ClientsQuery = {
  __typename?: 'Query'
  clients: Array<{
    __typename?: 'Client'
    id: string
    name: string
    code?: string | null
  }>
}

export type UserDetailsQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  teamsIds?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >
}>

export type UserDetailsQuery = {
  __typename?: 'Query'
  userDetails: Array<{
    __typename?: 'UserDetail'
    id?: string | null
    email: string
    roleId?: string | null
    accountStatus: string
    jobTitle?: string | null
    role?: { __typename?: 'Role'; name: string; rank: number } | null
  }>
}

export type EmailWhitelistQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
}>

export type EmailWhitelistQuery = {
  __typename?: 'Query'
  emailWhitelist: Array<{
    __typename?: 'EmailWhitelist'
    id: string
    clientId: string
    emailOrDomain: string
  }>
}

export type CheckIfEmailWhitelistedQueryVariables = Exact<{
  client: Scalars['String']['input']
  email: Scalars['String']['input']
}>

export type CheckIfEmailWhitelistedQuery = {
  __typename?: 'Query'
  checkIfEmailWhitelisted?: boolean | null
}

export type YearsInPurposeSignatureChartQueryVariables = Exact<{
  [key: string]: never
}>

export type YearsInPurposeSignatureChartQuery = {
  __typename?: 'Query'
  yearsInPurposeSignatureChart?: Array<number | null> | null
}

export type CompaniesInPurposeSignatureChartQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
}>

export type CompaniesInPurposeSignatureChartQuery = {
  __typename?: 'Query'
  companiesInPurposeSignatureChart?: Array<{
    __typename?: 'Company'
    id?: string | null
    name?: string | null
    clientCode?: string | null
  } | null> | null
}

export type TeamPurposeSignatureQueryVariables = Exact<{
  year: Scalars['Int']['input']
  companyId: Scalars['ID']['input']
  groupId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type TeamPurposeSignatureQuery = {
  __typename?: 'Query'
  teamPurposeSignature?: {
    __typename?: 'PurposeSignature'
    data?: Array<{
      __typename?: 'PurposeSignatureDate'
      score?: number | null
      name?: string | null
      current?: number | null
    } | null> | null
    optimum?: Array<{
      __typename?: 'PurposeSignatureDate'
      score?: number | null
      name?: string | null
    } | null> | null
    dataForSignatureMatch?: Array<{
      __typename?: 'DataForSignatureMatch'
      name?: string | null
      y?: number | null
    } | null> | null
  } | null
}

export type SessionPurposeSignatureQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  groupId?: InputMaybe<Scalars['ID']['input']>
  companyId?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  restoreUserSession?: InputMaybe<Scalars['Boolean']['input']>
}>

export type SessionPurposeSignatureQuery = {
  __typename?: 'Query'
  sessionPurposeSignature?: {
    __typename?: 'PurposeSignature'
    data?: Array<{
      __typename?: 'PurposeSignatureDate'
      score?: number | null
      name?: string | null
      current?: number | null
    } | null> | null
    optimum?: Array<{
      __typename?: 'PurposeSignatureDate'
      score?: number | null
      name?: string | null
    } | null> | null
    company?: Array<{
      __typename?: 'PurposeSignatureDate'
      score?: number | null
      name?: string | null
    } | null> | null
    dataForSignatureMatch?: Array<{
      __typename?: 'DataForSignatureMatch'
      name?: string | null
      y?: number | null
      original?: number | null
      company?: number | null
      scoreVsCompany?: number | null
      originalVsCompany?: number | null
    } | null> | null
  } | null
}

export type PurposeDriversSignatureQueryVariables = Exact<{
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type PurposeDriversSignatureQuery = {
  __typename?: 'Query'
  purposeDriversSignature?: Array<{
    __typename?: 'PurposeDriver'
    name?: string | null
    value?: number | null
    color?: string | null
    differenceWithPastQuarter?: number | null
    compareVsPeriod?: string | null
  } | null> | null
}

export type ReputationScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type ReputationScoreQuery = {
  __typename?: 'Query'
  reputationScore?: {
    __typename?: 'ReputationScore'
    score: number
    difference?: number | null
    compareVsPeriod?: string | null
  } | null
}

export type CompanyVsTopMostPurposeDrivenQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type CompanyVsTopMostPurposeDrivenQuery = {
  __typename?: 'Query'
  companyVsTopMostPurposeDriven?: Array<{
    __typename?: 'PurposeDriver'
    name?: string | null
    value?: number | null
    color?: string | null
    differenceWithPastQuarter?: number | null
    compareVsPeriod?: string | null
  } | null> | null
}

export type PurposePillarsQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type PurposePillarsQuery = {
  __typename?: 'Query'
  purposePillars?: Array<{
    __typename?: 'PurposePillar'
    name?: string | null
    value?: number | null
    previousValue?: number | null
    compareVs?: string | null
    color?: string | null
    optimum?: number | null
    drilldown?: Array<{
      __typename?: 'SignatureVariable'
      name?: string | null
      value?: number | null
      previousValue?: number | null
      compareVs?: string | null
      drilldown?: Array<{
        __typename?: 'Variable'
        name?: string | null
        value?: number | null
        previousValue?: number | null
        compareVs?: string | null
      } | null> | null
      optimumDrilldown?: Array<{
        __typename?: 'Variable'
        name?: string | null
        value?: number | null
      } | null> | null
    } | null> | null
    optimumDrilldown?: Array<{
      __typename?: 'SignatureVariable'
      name?: string | null
      value?: number | null
    } | null> | null
  } | null> | null
}

export type HighRiskAreasQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type HighRiskAreasQuery = {
  __typename?: 'Query'
  highRiskAreas?: Array<{
    __typename?: 'PurposePillar'
    name?: string | null
    value?: number | null
    previousValue?: number | null
    compareVs?: string | null
    color?: string | null
    optimum?: number | null
    drilldown?: Array<{
      __typename?: 'SignatureVariable'
      name?: string | null
      value?: number | null
      previousValue?: number | null
      compareVs?: string | null
    } | null> | null
    optimumDrilldown?: Array<{
      __typename?: 'SignatureVariable'
      name?: string | null
      value?: number | null
    } | null> | null
  } | null> | null
}

export type SignatureMatchQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type SignatureMatchQuery = {
  __typename?: 'Query'
  signatureMatch?: Array<{
    __typename?: 'PurposePillar'
    name?: string | null
    value?: number | null
    previousValue?: number | null
    compareVs?: string | null
    color?: string | null
    optimum?: number | null
    drilldown?: Array<{
      __typename?: 'SignatureVariable'
      name?: string | null
      value?: number | null
      previousValue?: number | null
      compareVs?: string | null
    } | null> | null
    optimumDrilldown?: Array<{
      __typename?: 'SignatureVariable'
      name?: string | null
      value?: number | null
    } | null> | null
  } | null> | null
}

export type ExploreDataByYearQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type ExploreDataByYearQuery = {
  __typename?: 'Query'
  exploreDataByYear?: {
    __typename?: 'ExploreCompanyData'
    categories?: Array<string | null> | null
    data?: Array<{
      __typename?: 'ExploreCompanyDataSlice'
      name?: string | null
      color?: string | null
      data?: Array<number | null> | null
    } | null> | null
  } | null
}

export type ExploreDataByIndustryQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type ExploreDataByIndustryQuery = {
  __typename?: 'Query'
  exploreDataByIndustry?: {
    __typename?: 'ExploreCompanyData'
    categories?: Array<string | null> | null
    average?: Array<number | null> | null
    data?: Array<{
      __typename?: 'ExploreCompanyDataSlice'
      name?: string | null
      color?: string | null
      data?: Array<number | null> | null
    } | null> | null
  } | null
}

export type GetTrendingTopicsQueryVariables = Exact<{
  industryId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetTrendingTopicsQuery = {
  __typename?: 'Query'
  trendingTopics?: Array<{
    __typename?: 'TrendingTopic'
    score?: number | null
    averageScore?: number | null
    variableId: string
    variableName: string
    pillarName?: string | null
    dataType: RefinitivDataType
  } | null> | null
}

export type IndustriesQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
}>

export type IndustriesQuery = {
  __typename?: 'Query'
  industries: Array<{
    __typename?: 'Industry'
    id: string
    name: string
    forClient?: boolean | null
  }>
}

export type PurposeMatchQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
}>

export type PurposeMatchQuery = {
  __typename?: 'Query'
  purposeMatch?: number | null
}

export type VariableGroupsQueryVariables = Exact<{ [key: string]: never }>

export type VariableGroupsQuery = {
  __typename?: 'Query'
  variableGroups?: Array<{
    __typename?: 'VariableGroup'
    id?: string | null
    name?: string | null
  } | null> | null
}

export type GetHomeTabSentimentScoreQueryVariables = Exact<{
  industryId: Scalars['ID']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetHomeTabSentimentScoreQuery = {
  __typename?: 'Query'
  sentimentScore?: Array<{
    __typename?: 'SentimentScore'
    score?: number | null
    averageScore?: number | null
    leaderScore?: number | null
    dataType: RefinitivDataType
  } | null> | null
}

export type GetHomeTabReputationScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetHomeTabReputationScoreQuery = {
  __typename?: 'Query'
  reputationScore?: {
    __typename?: 'ReputationScore'
    score: number
    averageScore?: number | null
    leaderScore?: number | null
  } | null
}

export type GetHomeTabPerceptionRealityGapScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetHomeTabPerceptionRealityGapScoreQuery = {
  __typename?: 'Query'
  perceptionRealityGapScore?: Array<{
    __typename?: 'PerceptionRealityGapScore'
    score?: number | null
    averageScore?: number | null
    leaderScore?: number | null
    dataType: RefinitivDataType
  } | null> | null
}

export type GetNotificationsQueryVariables = Exact<{ [key: string]: never }>

export type GetNotificationsQuery = {
  __typename?: 'Query'
  notifications: Array<{
    __typename?: 'Notification'
    id: number
    message: string
    read: boolean
    createdAt: string
  }>
}

export type MarkNotificationAsReadMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type MarkNotificationAsReadMutation = {
  __typename?: 'Mutation'
  markNotificationAsRead: boolean
}

export type CalculateBehaviorDataMutationVariables = Exact<{
  start?: InputMaybe<Scalars['String']['input']>
  end?: InputMaybe<Scalars['String']['input']>
}>

export type CalculateBehaviorDataMutation = {
  __typename?: 'Mutation'
  calculateBehaviorData: Array<{
    __typename?: 'PullBehaviorDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullCalculateBehaviorDataJobStatusQueryVariables = Exact<{
  jobs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type PullCalculateBehaviorDataJobStatusQuery = {
  __typename?: 'Query'
  pullCalculateBehaviorDataJobStatus: Array<{
    __typename?: 'PullBehaviorDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type DownloadPerceptionRealityGapDataQueryVariables = Exact<{
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type DownloadPerceptionRealityGapDataQuery = {
  __typename?: 'Query'
  downloadPerceptionRealityGapData?: string | null
}

export type PullArticlesMutationVariables = Exact<{
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
}>

export type PullArticlesMutation = {
  __typename?: 'Mutation'
  pullArticles: Array<{
    __typename?: 'PullOnclusiveArticlesJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullArticlesJobStatusQueryVariables = Exact<{
  jobs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type PullArticlesJobStatusQuery = {
  __typename?: 'Query'
  pullArticlesJobStatus: Array<{
    __typename?: 'PullOnclusiveArticlesJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullCategorizedArticlesMutationVariables = Exact<{
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
  limit: Scalars['Int']['input']
}>

export type PullCategorizedArticlesMutation = {
  __typename?: 'Mutation'
  pullCategorizedArticles: Array<{
    __typename?: 'PullCategorizedArticlesJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type CancelCategorizationJobsMutationVariables = Exact<{
  [key: string]: never
}>

export type CancelCategorizationJobsMutation = {
  __typename?: 'Mutation'
  cancelCategorizationJobs: boolean
}

export type PullCategorizedArticlesJobStatusQueryVariables = Exact<{
  jobs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type PullCategorizedArticlesJobStatusQuery = {
  __typename?: 'Query'
  pullCategorizedArticlesJobStatus: Array<{
    __typename?: 'PullCategorizedArticlesJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullCompanyExplorerDataMutationVariables = Exact<{
  date: Scalars['String']['input']
}>

export type PullCompanyExplorerDataMutation = {
  __typename?: 'Mutation'
  pullCompanyExplorerData: Array<{
    __typename?: 'PullCompanyExplorerDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullCompanyExplorerDataJobStatusQueryVariables = Exact<{
  jobs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type PullCompanyExplorerDataJobStatusQuery = {
  __typename?: 'Query'
  pullCompanyExplorerDataJobStatus: Array<{
    __typename?: 'PullCompanyExplorerDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullInsightsMutationVariables = Exact<{
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
}>

export type PullInsightsMutation = {
  __typename?: 'Mutation'
  pullInsights: Array<{
    __typename?: 'PullInsightsJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullInsightsJobStatusQueryVariables = Exact<{
  jobs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type PullInsightsJobStatusQuery = {
  __typename?: 'Query'
  pullInsightsJobStatus: Array<{
    __typename?: 'PullInsightsJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullRefinitivDataJobStatusQueryVariables = Exact<{
  jobId: Scalars['String']['input']
}>

export type PullRefinitivDataJobStatusQuery = {
  __typename?: 'Query'
  pullRefinitivDataJobStatus: {
    __typename?: 'PullRefinitivDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }
}

export type PullSentimentHistoryDataMutationVariables = Exact<{
  start: Scalars['String']['input']
  end: Scalars['String']['input']
}>

export type PullSentimentHistoryDataMutation = {
  __typename?: 'Mutation'
  pullSentimentHistoryData: Array<{
    __typename?: 'PullSentimentHistoryDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullSentimentHistoryDataJobStatusQueryVariables = Exact<{
  jobs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type PullSentimentHistoryDataJobStatusQuery = {
  __typename?: 'Query'
  pullSentimentHistoryDataJobStatus: Array<{
    __typename?: 'PullSentimentHistoryDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type BehaviorDataQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  start: Scalars['String']['input']
  end: Scalars['String']['input']
}>

export type BehaviorDataQuery = {
  __typename?: 'Query'
  behaviorData: Array<{
    __typename?: 'BehaviorDataRecord'
    company_id?: string | null
    year?: number | null
    quarter?: number | null
    behavior_score?: number | null
    community?: number | null
    customers?: number | null
    dei?: number | null
    employees?: number | null
    environment?: number | null
    financials?: number | null
    governance?: number | null
    community_development?: number | null
    compliance_reporting?: number | null
    diversity?: number | null
    employee_compensation?: number | null
    employee_engagement?: number | null
    ethics?: number | null
    financial_performance?: number | null
    health_safety?: number | null
    human_rights_and_equality?: number | null
    leadership?: number | null
    loyalty?: number | null
    net_zero?: number | null
    products_services?: number | null
    reach?: number | null
    resource_management?: number | null
    supplier?: number | null
    vision?: number | null
  }>
}

export type CustomerReportsQueryVariables = Exact<{ [key: string]: never }>

export type CustomerReportsQuery = {
  __typename?: 'Query'
  customerReports: Array<{
    __typename?: 'CustomerReport'
    id: number
    name: string
    createdAt?: string | null
    customer?: { __typename?: 'Customer'; id: number; name: string } | null
  }>
}

export type UploadCustomerReportFileMutationVariables = Exact<{
  file: Scalars['Upload']['input']
  clientId: Scalars['ID']['input']
}>

export type UploadCustomerReportFileMutation = {
  __typename?: 'Mutation'
  uploadCustomerReportFile: {
    __typename?: 'ReportCommandResult'
    code: number
    success: boolean
    message?: string | null
  }
}

export type DeleteCustomerReportMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteCustomerReportMutation = {
  __typename?: 'Mutation'
  deleteCustomerReport: {
    __typename?: 'ReportCommandResult'
    code: number
    success: boolean
    message?: string | null
  }
}

export type CategorizedArticlesJobsQueryVariables = Exact<{
  [key: string]: never
}>

export type CategorizedArticlesJobsQuery = {
  __typename?: 'Query'
  categorizedArticlesJobs?: Array<{
    __typename?: 'CategorizedArticleJob'
    status?: string | null
    jobId: string
    tag?: string | null
    jobsLeft?: number | null
    rate?: number | null
    startDate?: string | null
    endDate?: string | null
    result?: string | null
    createdAt?: string | null
  }> | null
}

export type SentimentDataQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  start: Scalars['String']['input']
  end: Scalars['String']['input']
}>

export type SentimentDataQuery = {
  __typename?: 'Query'
  sentimentData: Array<{
    __typename?: 'SentimentDataRecord'
    company_id?: string | null
    month?: number | null
    year?: number | null
    sentiment_score?: number | null
    community?: number | null
    customers?: number | null
    dei?: number | null
    employees?: number | null
    environment?: number | null
    financials?: number | null
    governance?: number | null
    community_development?: number | null
    compliance_reporting?: number | null
    diversity?: number | null
    employee_compensation?: number | null
    employee_engagement?: number | null
    ethics?: number | null
    financial_performance?: number | null
    health_safety?: number | null
    human_rights_and_equality?: number | null
    leadership?: number | null
    loyalty?: number | null
    net_zero?: number | null
    products_services?: number | null
    reach?: number | null
    resource_management?: number | null
    supplier?: number | null
    vision?: number | null
  }>
}

export type AddSubscriptionMutationVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
  features: Array<PaidFeature> | PaidFeature
}>

export type AddSubscriptionMutation = {
  __typename?: 'Mutation'
  addSubscription?: {
    __typename?: 'ClientSubscription'
    id: string
    startDate?: string | null
    endDate?: string | null
    features?: Array<PaidFeature | null> | null
    updatedAt?: string | null
    createdAt?: string | null
    client: {
      __typename?: 'Client'
      id: string
      name: string
      code?: string | null
    }
  } | null
}

export type SubscriptionsQueryVariables = Exact<{ [key: string]: never }>

export type SubscriptionsQuery = {
  __typename?: 'Query'
  subscriptions: Array<{
    __typename?: 'ClientSubscription'
    id: string
    startDate?: string | null
    endDate?: string | null
    features?: Array<PaidFeature | null> | null
    updatedAt?: string | null
    createdAt?: string | null
    client: {
      __typename?: 'Client'
      id: string
      name: string
      code?: string | null
    }
  }>
}

export type CheckActiveSubscriptionQueryVariables = Exact<{
  features: Array<PaidFeature> | PaidFeature
}>

export type CheckActiveSubscriptionQuery = {
  __typename?: 'Query'
  checkActiveSubscription: boolean
}

export type GetAnalystRatingsQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetAnalystRatingsQuery = {
  __typename?: 'Query'
  analystRatings: Array<{
    __typename?: 'AnalystRating'
    date: string
    analyst?: string | null
    analystName?: string | null
    ratingCurrent?: string | null
    ratingPrior?: string | null
    actionPt?: string | null
    ptCurrent?: number | null
    ptPrior?: number | null
  }>
}

export type GetAverageRatingQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetAverageRatingQuery = {
  __typename?: 'Query'
  averageRatings: {
    __typename?: 'AverageRating'
    quarter: string
    nextQuarter: string
    current: number
    predicted: number
    reputationAttributes: Array<string>
  }
}

export type GetFinancialRatingQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetFinancialRatingQuery = {
  __typename?: 'Query'
  ratings: Array<{
    __typename?: 'FinancialRating'
    averageRating?: number | null
    month: string
    sell?: number | null
    buy?: number | null
    hold?: number | null
    underperform?: number | null
    overperform?: number | null
  }>
}

export type SentimentDriversQueryVariables = Exact<{
  industryId: Scalars['ID']['input']
}>

export type SentimentDriversQuery = {
  __typename?: 'Query'
  rankedSentimentDrivers: Array<{
    __typename?: 'SentimentDriver'
    variableId: string
    variableName: string
    pillarName: string
  }>
}

export type GetOverallSentimentOverTimeQueryVariables = Exact<{
  industryId: Scalars['ID']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetOverallSentimentOverTimeQuery = {
  __typename?: 'Query'
  sentimentOvertimeScore?: {
    __typename?: 'SentimentOverTime'
    overallSentiment?: Array<{
      __typename?: 'SentimentScore'
      score?: number | null
      date?: string | null
      dataType: RefinitivDataType
    } | null> | null
  } | null
}

export type GetStockClosePriceOvertimeQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetStockClosePriceOvertimeQuery = {
  __typename?: 'Query'
  stockClosePriceOvertime: Array<{
    __typename?: 'StockClosePrice'
    updatedAt: string
    adjustedClosingPrice?: number | null
  }>
}

export type GetVariableSentimentOverTimeQueryVariables = Exact<{
  industryId: Scalars['ID']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetVariableSentimentOverTimeQuery = {
  __typename?: 'Query'
  variableOvertimeScore?: Array<{
    __typename: 'VariableOverTimeScore'
    score?: number | null
    date?: string | null
    dataType: RefinitivDataType
    variableName?: string | null
    variableWeight?: number | null
    pillarName?: string | null
  } | null> | null
}

export type GetPerceptionRealityGapQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetPerceptionRealityGapQuery = {
  __typename?: 'Query'
  perceptionRealityGap?: {
    __typename?: 'PerceptionRealityGap'
    perception?: Array<{
      __typename?: 'Perception'
      score?: number | null
      averageScore?: number | null
      variableId: string
      variableName: string
      dataType: RefinitivDataType
    } | null> | null
    signature?: Array<{
      __typename?: 'Signature'
      name?: string | null
      pillar?: string | null
      value?: number | null
      optimum?: number | null
    } | null> | null
  } | null
}

export type GetArticlesByCompanyQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetArticlesByCompanyQuery = {
  __typename?: 'Query'
  articlesByCompany: Array<{
    __typename?: 'Article'
    domain?: string | null
    title?: string | null
    url?: string | null
    author?: string | null
    summary?: string | null
    sentiment?: string | null
    sentiment_pos?: number | null
    sentiment_neg?: number | null
    published_on?: string | null
    media?: string | null
    amplification?: number | null
    reach?: number | null
    pagerank?: number | null
    country?: string | null
    lang?: string | null
  }>
}

export type DailyMentionsQueryVariables = Exact<{
  industryId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type DailyMentionsQuery = {
  __typename?: 'Query'
  dailyMentions?: Array<{
    __typename?: 'Mention'
    timestamp: string
    mentions: number
    company: string
    companyId: string
    dataType: RefinitivDataType
  } | null> | null
}

export type ArticlesByPillarsQueryVariables = Exact<{
  variableId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type ArticlesByPillarsQuery = {
  __typename?: 'Query'
  articlesByPillars: Array<{
    __typename?: 'Article'
    domain?: string | null
    title?: string | null
    url?: string | null
    author?: string | null
    summary?: string | null
    sentiment?: string | null
    sentiment_pos?: number | null
    sentiment_neg?: number | null
    published_on?: string | null
    media?: string | null
    amplification?: number | null
    reach?: number | null
    pagerank?: number | null
    country?: string | null
    lang?: string | null
  }>
}

export type GetSentimentByPillarQueryVariables = Exact<{
  industryId: Scalars['ID']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetSentimentByPillarQuery = {
  __typename?: 'Query'
  sentimentByPillar?: Array<{
    __typename?: 'SentimentData'
    score?: number | null
    averageScore?: number | null
    leaderScore?: number | null
    variableId: string
    variableName: string
    dataType: RefinitivDataType
  } | null> | null
}

export type ArticlesSentimentOvertimeQueryVariables = Exact<{
  sentiment: Sentiment
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type ArticlesSentimentOvertimeQuery = {
  __typename?: 'Query'
  articlesSentimentOvertime: Array<{
    __typename?: 'Article'
    domain?: string | null
    title?: string | null
    url?: string | null
    author?: string | null
    summary?: string | null
    sentiment?: string | null
    sentiment_pos?: number | null
    sentiment_neg?: number | null
    published_on?: string | null
    media?: string | null
    amplification?: number | null
    reach?: number | null
    pagerank?: number | null
    country?: string | null
    lang?: string | null
  }>
}

export type GetSentimentOverTimeQueryVariables = Exact<{
  industryId: Scalars['ID']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetSentimentOverTimeQuery = {
  __typename?: 'Query'
  sentimentOvertimeScore?: {
    __typename?: 'SentimentOverTime'
    overallSentiment?: Array<{
      __typename?: 'SentimentScore'
      score?: number | null
      date?: string | null
      dataType: RefinitivDataType
    } | null> | null
    positiveSentiment?: Array<{
      __typename?: 'SentimentScore'
      score?: number | null
      date?: string | null
      dataType: RefinitivDataType
    } | null> | null
    negativeSentiment?: Array<{
      __typename?: 'SentimentScore'
      score?: number | null
      date?: string | null
      dataType: RefinitivDataType
    } | null> | null
  } | null
}

export type SentimentScoreArticlesQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type SentimentScoreArticlesQuery = {
  __typename?: 'Query'
  articlesByPillars: Array<{
    __typename?: 'Article'
    domain?: string | null
    title?: string | null
    url?: string | null
    author?: string | null
    summary?: string | null
    sentiment?: string | null
    sentiment_pos?: number | null
    sentiment_neg?: number | null
    published_on?: string | null
    media?: string | null
    amplification?: number | null
    reach?: number | null
    pagerank?: number | null
    country?: string | null
    lang?: string | null
  }>
}

export type GetSentimentScoreQueryVariables = Exact<{
  industryId: Scalars['ID']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetSentimentScoreQuery = {
  __typename?: 'Query'
  sentimentScore?: Array<{
    __typename?: 'SentimentScore'
    score?: number | null
    averageScore?: number | null
    leaderScore?: number | null
    dataType: RefinitivDataType
  } | null> | null
}

export type ArticlesByTrendingTopicsQueryVariables = Exact<{
  variableId: Scalars['ID']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type ArticlesByTrendingTopicsQuery = {
  __typename?: 'Query'
  articlesByTrendingTopics: Array<{
    __typename?: 'Article'
    domain?: string | null
    title?: string | null
    url?: string | null
    author?: string | null
    summary?: string | null
    sentiment?: string | null
    sentiment_pos?: number | null
    sentiment_neg?: number | null
    published_on?: string | null
    media?: string | null
    amplification?: number | null
    reach?: number | null
    pagerank?: number | null
    country?: string | null
    lang?: string | null
  }>
}

export type InsightsQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
}>

export type InsightsQuery = {
  __typename?: 'Query'
  insights: { __typename?: 'Insight'; companyId: number; summary: string }
}

export type GetArticlesCountQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetArticlesCountQuery = {
  __typename?: 'Query'
  articlesCount: Array<{
    __typename?: 'ArticlesCount'
    total: number
    date: string
  }>
}

export type GetArticlesOnclusiveQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetArticlesOnclusiveQuery = {
  __typename?: 'Query'
  articles: Array<{
    __typename?: 'Article'
    domain?: string | null
    title?: string | null
    url?: string | null
    author?: string | null
    summary?: string | null
    sentiment?: string | null
    sentiment_pos?: number | null
    sentiment_neg?: number | null
    published_on?: string | null
    media?: string | null
    amplification?: number | null
    reach?: number | null
    pagerank?: number | null
    country?: string | null
    lang?: string | null
  }>
}

export type GetArticlesSentimentQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetArticlesSentimentQuery = {
  __typename?: 'Query'
  articlesSentiment: {
    __typename?: 'ArticlesSentiment'
    positive?: number | null
    negative?: number | null
    neutral?: number | null
  }
}

export type GetShareVoiceQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetShareVoiceQuery = {
  __typename?: 'Query'
  shareVoice: Array<{
    __typename?: 'ShareVoice'
    company: string
    share?: number | null
  }>
}

export type CustomerReportsByClientQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
}>

export type CustomerReportsByClientQuery = {
  __typename?: 'Query'
  customerReportsByClient: Array<{
    __typename?: 'CustomerReport'
    id: number
    name: string
    createdAt?: string | null
  }>
}

export type GetReputationScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetReputationScoreQuery = {
  __typename?: 'Query'
  reputationScore?: {
    __typename?: 'ReputationScore'
    score: number
    difference?: number | null
    compareVsPeriod?: string | null
  } | null
}

export type GetSentimentExecutiveSummaryQueryVariables = Exact<{
  industryId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  source?: InputMaybe<RefinitivDataSource>
}>

export type GetSentimentExecutiveSummaryQuery = {
  __typename?: 'Query'
  sentimentExecutiveSummary?: {
    __typename?: 'SentimentExecutiveSummary'
    mentions?: Array<{
      __typename?: 'Mentions'
      companyId: string
      companyName: string
      count?: number | null
    } | null> | null
    score?: Array<{
      __typename?: 'ExecutiveSummarySentimentScore'
      companyId: string
      companyName: string
      score?: number | null
    } | null> | null
  } | null
}

export type GetSignatureExecutiveSummaryQueryVariables = Exact<{
  industryId?: InputMaybe<Scalars['ID']['input']>
  clientId: Scalars['ID']['input']
}>

export type GetSignatureExecutiveSummaryQuery = {
  __typename?: 'Query'
  signatureExecutiveSummary?: {
    __typename?: 'SignatureExecutiveSummary'
    drivers?: Array<{
      __typename?: 'ReputationDriver'
      variableId: string
      variableName: string
      weight?: number | null
    } | null> | null
    competitorsGains?: Array<{
      __typename?: 'CompanyGain'
      companyId: string
      companyName: string
      score?: number | null
      differenceVsPrevious?: number | null
    } | null> | null
  } | null
}

export type GetReputationScoreV2QueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetReputationScoreV2Query = {
  __typename?: 'Query'
  reputationScoreV2: { __typename?: 'ReputationScoreV2'; value: number }
}

export type GetReputationScoreChangeQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetReputationScoreChangeQuery = {
  __typename?: 'Query'
  reputationScoreChangeV2: { __typename?: 'ReputationScoreV2'; value: number }
}

export type GetReputationScoreDiverQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetReputationScoreDiverQuery = {
  __typename?: 'Query'
  reputationScoreDriverV2: { __typename?: 'ReputationScoreV2'; value: number }
}

export type GetMarketShareQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetMarketShareQuery = {
  __typename?: 'Query'
  marketShare: { __typename?: 'ReputationScoreV2'; value: number }
}

export type GetMediaMentionsQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetMediaMentionsQuery = {
  __typename?: 'Query'
  mediaMentions: { __typename?: 'ReputationScoreV2'; value: number }
}

export type GetReputationInfluencersQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetReputationInfluencersQuery = {
  __typename?: 'Query'
  reputationInfluencers: {
    __typename?: 'Influencers'
    values?: Array<{
      __typename?: 'Influencer'
      title: string
      url: string
      domain: string
      published_on: string
    }> | null
  }
}

export type GetTrafficValueQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetTrafficValueQuery = {
  __typename?: 'Query'
  trafficValue: { __typename?: 'ReputationScoreV2'; value: number }
}

export type GetPillarReputationQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetPillarReputationQuery = {
  __typename?: 'Query'
  pillarReputationScore: {
    __typename?: 'PillarReputationScore'
    value: {
      __typename?: 'ScoresValue'
      Employees?: number | null
      Financials?: number | null
      Community?: number | null
      Environment?: number | null
    }
  }
}

export type GetPillarReputationChangesQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetPillarReputationChangesQuery = {
  __typename?: 'Query'
  pillarReputationChangesScore: {
    __typename?: 'PillarReputationScore'
    value: {
      __typename?: 'ScoresValue'
      Employees?: number | null
      Financials?: number | null
      Community?: number | null
      Environment?: number | null
    }
  }
}

export type GetReputationScorePotentialQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetReputationScorePotentialQuery = {
  __typename?: 'Query'
  reputationScorePotential: { __typename?: 'ReputationScoreV2'; value: number }
}

export type GetReputationScorePotentialKeyOpportunityQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetReputationScorePotentialKeyOpportunityQuery = {
  __typename?: 'Query'
  reputationScorePotentialKeyOpportunity: {
    __typename?: 'KeyOpportunity'
    value: Array<number>
  }
}

export type GetScoreRelevanceGraphQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetScoreRelevanceGraphQuery = {
  __typename?: 'Query'
  scoresRelevanceGraph?: Array<{
    __typename?: 'Brands'
    company?: string | null
    data?: Array<{
      __typename?: 'BrandData'
      value?: number | null
      date?: string | null
    } | null> | null
  }> | null
}

export type GetScoresSentimentGraphQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetScoresSentimentGraphQuery = {
  __typename?: 'Query'
  scoresSentimentGraph?: Array<{
    __typename?: 'Brands'
    company?: string | null
    data?: Array<{
      __typename?: 'BrandData'
      value?: number | null
      date?: string | null
    } | null> | null
  }> | null
}

export type GetAlertsQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
}>

export type GetAlertsQuery = {
  __typename?: 'Query'
  alerts?: Array<{
    __typename?: 'Alert'
    variable_name: string
    variable_id: number
    definition: string
    avg_score: number
    trend: number
    stddev: number
    daily_avg_score: number
    difference: number
  }> | null
}

export const LoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Login' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'login' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'respondentUuid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'clientName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>
export const LogoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Logout' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'logout' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>
export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rank' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>
export const DeleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>
export const EditUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rank' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountStatus' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditUserMutation, EditUserMutationVariables>
export const UploadUsersRosterFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadUsersRosterFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadUsersRosterFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadUsersRosterFileMutation,
  UploadUsersRosterFileMutationVariables
>
export const SendResetPasswordEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendResetPasswordEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'redirectTo' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendResetPasswordEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectTo' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'redirectTo' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendResetPasswordEmailMutation,
  SendResetPasswordEmailMutationVariables
>
export const VerifyResetPasswordTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyResetPasswordToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyResetPasswordToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyResetPasswordTokenMutation,
  VerifyResetPasswordTokenMutationVariables
>
export const ChangeUserPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeUserPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeUserPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newPassword' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangeUserPasswordMutation,
  ChangeUserPasswordMutationVariables
>
export const ChangePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newPassword' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>
export const AddEmailToWhitelistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddEmailToWhitelist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emailOrDomain' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addEmailToWhitelist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailOrDomain' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'emailOrDomain' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddEmailToWhitelistMutation,
  AddEmailToWhitelistMutationVariables
>
export const ChangeVariableScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'changeVariableScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'variableName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'value' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeVariableScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'variableName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'variableName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'value' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'current' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangeVariableScoreMutation,
  ChangeVariableScoreMutationVariables
>
export const ResetVariableScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resetVariableScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetVariableScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'current' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetVariableScoreMutation,
  ResetVariableScoreMutationVariables
>
export const PullRefinitivDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pullRefinitivData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullRefinitivData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'end' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullRefinitivDataMutation,
  PullRefinitivDataMutationVariables
>
export const CreateChatDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createChat' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createChat' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateChatMutation, CreateChatMutationVariables>
export const CreateMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'chatId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMessageMutation,
  CreateMessageMutationVariables
>
export const CurrentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'currentUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'respondentUuid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'clientName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailHash' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mustResetPassword' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentUserQuery, CurrentUserQueryVariables>
export const ClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'clients' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientsQuery, ClientsQueryVariables>
export const UserDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamsIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamsIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamsIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserDetailsQuery, UserDetailsQueryVariables>
export const EmailWhitelistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmailWhitelist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailWhitelist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailOrDomain' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailWhitelistQuery, EmailWhitelistQueryVariables>
export const CheckIfEmailWhitelistedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckIfEmailWhitelisted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'client' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkIfEmailWhitelisted' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'client' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'client' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckIfEmailWhitelistedQuery,
  CheckIfEmailWhitelistedQueryVariables
>
export const YearsInPurposeSignatureChartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'yearsInPurposeSignatureChart' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yearsInPurposeSignatureChart' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  YearsInPurposeSignatureChartQuery,
  YearsInPurposeSignatureChartQueryVariables
>
export const CompaniesInPurposeSignatureChartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'companiesInPurposeSignatureChart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companiesInPurposeSignatureChart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompaniesInPurposeSignatureChartQuery,
  CompaniesInPurposeSignatureChartQueryVariables
>
export const TeamPurposeSignatureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'teamPurposeSignature' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamPurposeSignature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'year' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'current' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optimum' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataForSignatureMatch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TeamPurposeSignatureQuery,
  TeamPurposeSignatureQueryVariables
>
export const SessionPurposeSignatureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'sessionPurposeSignature' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'restoreUserSession' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessionPurposeSignature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'restoreUserSession' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'restoreUserSession' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'current' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optimum' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataForSignatureMatch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'original' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scoreVsCompany' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalVsCompany' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SessionPurposeSignatureQuery,
  SessionPurposeSignatureQueryVariables
>
export const PurposeDriversSignatureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'purposeDriversSignature' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purposeDriversSignature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'differenceWithPastQuarter' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compareVsPeriod' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PurposeDriversSignatureQuery,
  PurposeDriversSignatureQueryVariables
>
export const ReputationScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'reputationScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                { kind: 'Field', name: { kind: 'Name', value: 'difference' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compareVsPeriod' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReputationScoreQuery,
  ReputationScoreQueryVariables
>
export const CompanyVsTopMostPurposeDrivenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'companyVsTopMostPurposeDriven' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyVsTopMostPurposeDriven' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'differenceWithPastQuarter' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compareVsPeriod' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyVsTopMostPurposeDrivenQuery,
  CompanyVsTopMostPurposeDrivenQueryVariables
>
export const PurposePillarsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'purposePillars' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purposePillars' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousValue' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'compareVs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optimum' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'drilldown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previousValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'compareVs' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'drilldown' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previousValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'compareVs' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optimumDrilldown' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optimumDrilldown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PurposePillarsQuery, PurposePillarsQueryVariables>
export const HighRiskAreasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'highRiskAreas' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highRiskAreas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousValue' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'compareVs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optimum' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'drilldown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previousValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'compareVs' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optimumDrilldown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HighRiskAreasQuery, HighRiskAreasQueryVariables>
export const SignatureMatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'signatureMatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signatureMatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousValue' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'compareVs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optimum' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'drilldown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previousValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'compareVs' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optimumDrilldown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignatureMatchQuery, SignatureMatchQueryVariables>
export const ExploreDataByYearDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'exploreDataByYear' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exploreDataByYear' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExploreDataByYearQuery,
  ExploreDataByYearQueryVariables
>
export const ExploreDataByIndustryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'exploreDataByIndustry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exploreDataByIndustry' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'average' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExploreDataByIndustryQuery,
  ExploreDataByIndustryQueryVariables
>
export const GetTrendingTopicsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTrendingTopics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trendingTopics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageScore' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'variableId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variableName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pillarName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTrendingTopicsQuery,
  GetTrendingTopicsQueryVariables
>
export const IndustriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'industries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'industries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'forClient' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IndustriesQuery, IndustriesQueryVariables>
export const PurposeMatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'purposeMatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purposeMatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PurposeMatchQuery, PurposeMatchQueryVariables>
export const VariableGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'variableGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variableGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VariableGroupsQuery, VariableGroupsQueryVariables>
export const GetHomeTabSentimentScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHomeTabSentimentScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageScore' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leaderScore' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHomeTabSentimentScoreQuery,
  GetHomeTabSentimentScoreQueryVariables
>
export const GetHomeTabReputationScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHomeTabReputationScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageScore' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leaderScore' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHomeTabReputationScoreQuery,
  GetHomeTabReputationScoreQueryVariables
>
export const GetHomeTabPerceptionRealityGapScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHomeTabPerceptionRealityGapScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'perceptionRealityGapScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageScore' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leaderScore' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHomeTabPerceptionRealityGapScoreQuery,
  GetHomeTabPerceptionRealityGapScoreQueryVariables
>
export const GetNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotifications' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'read' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNotificationsQuery,
  GetNotificationsQueryVariables
>
export const MarkNotificationAsReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkNotificationAsRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markNotificationAsRead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkNotificationAsReadMutation,
  MarkNotificationAsReadMutationVariables
>
export const CalculateBehaviorDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'calculateBehaviorData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calculateBehaviorData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'end' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CalculateBehaviorDataMutation,
  CalculateBehaviorDataMutationVariables
>
export const PullCalculateBehaviorDataJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullCalculateBehaviorDataJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullCalculateBehaviorDataJobStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullCalculateBehaviorDataJobStatusQuery,
  PullCalculateBehaviorDataJobStatusQueryVariables
>
export const DownloadPerceptionRealityGapDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'downloadPerceptionRealityGapData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadPerceptionRealityGapData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadPerceptionRealityGapDataQuery,
  DownloadPerceptionRealityGapDataQueryVariables
>
export const PullArticlesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pullArticles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullArticles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullArticlesMutation,
  PullArticlesMutationVariables
>
export const PullArticlesJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullArticlesJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullArticlesJobStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullArticlesJobStatusQuery,
  PullArticlesJobStatusQueryVariables
>
export const PullCategorizedArticlesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pullCategorizedArticles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullCategorizedArticles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullCategorizedArticlesMutation,
  PullCategorizedArticlesMutationVariables
>
export const CancelCategorizationJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelCategorizationJobs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelCategorizationJobs' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CancelCategorizationJobsMutation,
  CancelCategorizationJobsMutationVariables
>
export const PullCategorizedArticlesJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullCategorizedArticlesJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullCategorizedArticlesJobStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullCategorizedArticlesJobStatusQuery,
  PullCategorizedArticlesJobStatusQueryVariables
>
export const PullCompanyExplorerDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pullCompanyExplorerData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullCompanyExplorerData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'date' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullCompanyExplorerDataMutation,
  PullCompanyExplorerDataMutationVariables
>
export const PullCompanyExplorerDataJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullCompanyExplorerDataJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullCompanyExplorerDataJobStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullCompanyExplorerDataJobStatusQuery,
  PullCompanyExplorerDataJobStatusQueryVariables
>
export const PullInsightsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pullInsights' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullInsights' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullInsightsMutation,
  PullInsightsMutationVariables
>
export const PullInsightsJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullInsightsJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullInsightsJobStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullInsightsJobStatusQuery,
  PullInsightsJobStatusQueryVariables
>
export const PullRefinitivDataJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullRefinitivDataJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullRefinitivDataJobStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullRefinitivDataJobStatusQuery,
  PullRefinitivDataJobStatusQueryVariables
>
export const PullSentimentHistoryDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pullSentimentHistoryData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullSentimentHistoryData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'end' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullSentimentHistoryDataMutation,
  PullSentimentHistoryDataMutationVariables
>
export const PullSentimentHistoryDataJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullSentimentHistoryDataJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullSentimentHistoryDataJobStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullSentimentHistoryDataJobStatusQuery,
  PullSentimentHistoryDataJobStatusQueryVariables
>
export const BehaviorDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BehaviorData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'behaviorData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'end' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'behavior_score' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'community' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dei' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'environment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'financials' } },
                { kind: 'Field', name: { kind: 'Name', value: 'governance' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'community_development' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compliance_reporting' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'diversity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employee_compensation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employee_engagement' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ethics' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'financial_performance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'health_safety' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'human_rights_and_equality' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadership' } },
                { kind: 'Field', name: { kind: 'Name', value: 'loyalty' } },
                { kind: 'Field', name: { kind: 'Name', value: 'net_zero' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products_services' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reach' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resource_management' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'supplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vision' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BehaviorDataQuery, BehaviorDataQueryVariables>
export const CustomerReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomerReports' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerReports' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomerReportsQuery,
  CustomerReportsQueryVariables
>
export const UploadCustomerReportFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadCustomerReportFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadCustomerReportFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadCustomerReportFileMutation,
  UploadCustomerReportFileMutationVariables
>
export const DeleteCustomerReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCustomerReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCustomerReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCustomerReportMutation,
  DeleteCustomerReportMutationVariables
>
export const CategorizedArticlesJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CategorizedArticlesJobs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categorizedArticlesJobs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobsLeft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CategorizedArticlesJobsQuery,
  CategorizedArticlesJobsQueryVariables
>
export const SentimentDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SentimentData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'end' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_score' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'community' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dei' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'environment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'financials' } },
                { kind: 'Field', name: { kind: 'Name', value: 'governance' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'community_development' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compliance_reporting' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'diversity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employee_compensation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employee_engagement' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ethics' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'financial_performance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'health_safety' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'human_rights_and_equality' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadership' } },
                { kind: 'Field', name: { kind: 'Name', value: 'loyalty' } },
                { kind: 'Field', name: { kind: 'Name', value: 'net_zero' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products_services' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reach' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resource_management' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'supplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vision' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SentimentDataQuery, SentimentDataQueryVariables>
export const AddSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'features' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PaidFeature' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'features' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'features' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'features' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddSubscriptionMutation,
  AddSubscriptionMutationVariables
>
export const SubscriptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Subscriptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'features' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionsQuery, SubscriptionsQueryVariables>
export const CheckActiveSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckActiveSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'features' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PaidFeature' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkActiveSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'features' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'features' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckActiveSubscriptionQuery,
  CheckActiveSubscriptionQueryVariables
>
export const GetAnalystRatingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAnalystRatings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'analystRatings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'analyst' } },
                { kind: 'Field', name: { kind: 'Name', value: 'analystName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ratingCurrent' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ratingPrior' } },
                { kind: 'Field', name: { kind: 'Name', value: 'actionPt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ptCurrent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ptPrior' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAnalystRatingsQuery,
  GetAnalystRatingsQueryVariables
>
export const GetAverageRatingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAverageRating' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageRatings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'quarter' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextQuarter' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'predicted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reputationAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAverageRatingQuery,
  GetAverageRatingQueryVariables
>
export const GetFinancialRatingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFinancialRating' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ratings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageRating' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sell' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hold' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'underperform' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'overperform' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFinancialRatingQuery,
  GetFinancialRatingQueryVariables
>
export const SentimentDriversDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SentimentDrivers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankedSentimentDrivers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'variableId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variableName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pillarName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SentimentDriversQuery,
  SentimentDriversQueryVariables
>
export const GetOverallSentimentOverTimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOverallSentimentOverTime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentOvertimeScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'overallSentiment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOverallSentimentOverTimeQuery,
  GetOverallSentimentOverTimeQueryVariables
>
export const GetStockClosePriceOvertimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStockClosePriceOvertime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockClosePriceOvertime' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'adjustedClosingPrice' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetStockClosePriceOvertimeQuery,
  GetStockClosePriceOvertimeQueryVariables
>
export const GetVariableSentimentOverTimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetVariableSentimentOverTime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variableOvertimeScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variableName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variableWeight' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pillarName' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetVariableSentimentOverTimeQuery,
  GetVariableSentimentOverTimeQueryVariables
>
export const GetPerceptionRealityGapDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPerceptionRealityGap' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'perceptionRealityGap' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'perception' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'averageScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variableId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variableName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signature' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pillar' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optimum' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPerceptionRealityGapQuery,
  GetPerceptionRealityGapQueryVariables
>
export const GetArticlesByCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetArticlesByCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articlesByCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'author' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentiment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_pos' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_neg' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'published_on' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'media' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amplification' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reach' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pagerank' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetArticlesByCompanyQuery,
  GetArticlesByCompanyQueryVariables
>
export const DailyMentionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dailyMentions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyMentions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mentions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DailyMentionsQuery, DailyMentionsQueryVariables>
export const ArticlesByPillarsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArticlesByPillars' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'variableId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articlesByPillars' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'variableId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'variableId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'author' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentiment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_pos' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_neg' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'published_on' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'media' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amplification' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reach' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pagerank' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ArticlesByPillarsQuery,
  ArticlesByPillarsQueryVariables
>
export const GetSentimentByPillarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSentimentByPillar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentByPillar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageScore' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leaderScore' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variableId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variableName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSentimentByPillarQuery,
  GetSentimentByPillarQueryVariables
>
export const ArticlesSentimentOvertimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArticlesSentimentOvertime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sentiment' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Sentiment' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articlesSentimentOvertime' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sentiment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sentiment' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'author' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentiment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_pos' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_neg' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'published_on' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'media' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amplification' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reach' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pagerank' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ArticlesSentimentOvertimeQuery,
  ArticlesSentimentOvertimeQueryVariables
>
export const GetSentimentOverTimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSentimentOverTime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentOvertimeScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'overallSentiment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'positiveSentiment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'negativeSentiment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSentimentOverTimeQuery,
  GetSentimentOverTimeQueryVariables
>
export const SentimentScoreArticlesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SentimentScoreArticles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articlesByPillars' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'author' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentiment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_pos' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_neg' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'published_on' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'media' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amplification' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reach' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pagerank' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SentimentScoreArticlesQuery,
  SentimentScoreArticlesQueryVariables
>
export const GetSentimentScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSentimentScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageScore' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leaderScore' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSentimentScoreQuery,
  GetSentimentScoreQueryVariables
>
export const ArticlesByTrendingTopicsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArticlesByTrendingTopics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'variableId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articlesByTrendingTopics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'variableId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'variableId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'author' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentiment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_pos' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_neg' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'published_on' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'media' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amplification' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reach' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pagerank' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ArticlesByTrendingTopicsQuery,
  ArticlesByTrendingTopicsQueryVariables
>
export const InsightsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Insights' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insights' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InsightsQuery, InsightsQueryVariables>
export const GetArticlesCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetArticlesCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articlesCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetArticlesCountQuery,
  GetArticlesCountQueryVariables
>
export const GetArticlesOnclusiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetArticlesOnclusive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'author' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentiment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_pos' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_neg' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'published_on' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'media' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amplification' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reach' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pagerank' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetArticlesOnclusiveQuery,
  GetArticlesOnclusiveQueryVariables
>
export const GetArticlesSentimentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetArticlesSentiment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articlesSentiment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'positive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'negative' } },
                { kind: 'Field', name: { kind: 'Name', value: 'neutral' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetArticlesSentimentQuery,
  GetArticlesSentimentQueryVariables
>
export const GetShareVoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetShareVoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shareVoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                { kind: 'Field', name: { kind: 'Name', value: 'share' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetShareVoiceQuery, GetShareVoiceQueryVariables>
export const CustomerReportsByClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomerReportsByClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerReportsByClient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomerReportsByClientQuery,
  CustomerReportsByClientQueryVariables
>
export const GetReputationScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReputationScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                { kind: 'Field', name: { kind: 'Name', value: 'difference' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compareVsPeriod' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReputationScoreQuery,
  GetReputationScoreQueryVariables
>
export const GetSentimentExecutiveSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSentimentExecutiveSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'source' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RefinitivDataSource' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentExecutiveSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'source' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'source' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'score' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSentimentExecutiveSummaryQuery,
  GetSentimentExecutiveSummaryQueryVariables
>
export const GetSignatureExecutiveSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSignatureExecutiveSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signatureExecutiveSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'drivers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variableId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variableName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weight' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'competitorsGains' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'differenceVsPrevious' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSignatureExecutiveSummaryQuery,
  GetSignatureExecutiveSummaryQueryVariables
>
export const GetReputationScoreV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReputationScoreV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationScoreV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReputationScoreV2Query,
  GetReputationScoreV2QueryVariables
>
export const GetReputationScoreChangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReputationScoreChange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationScoreChangeV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReputationScoreChangeQuery,
  GetReputationScoreChangeQueryVariables
>
export const GetReputationScoreDiverDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReputationScoreDiver' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationScoreDriverV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReputationScoreDiverQuery,
  GetReputationScoreDiverQueryVariables
>
export const GetMarketShareDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMarketShare' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketShare' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMarketShareQuery, GetMarketShareQueryVariables>
export const GetMediaMentionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMediaMentions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaMentions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMediaMentionsQuery,
  GetMediaMentionsQueryVariables
>
export const GetReputationInfluencersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReputationInfluencers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationInfluencers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'domain' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'published_on' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReputationInfluencersQuery,
  GetReputationInfluencersQueryVariables
>
export const GetTrafficValueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTrafficValue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trafficValue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTrafficValueQuery,
  GetTrafficValueQueryVariables
>
export const GetPillarReputationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPillarReputation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarReputationScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Employees' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Financials' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Community' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Environment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPillarReputationQuery,
  GetPillarReputationQueryVariables
>
export const GetPillarReputationChangesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPillarReputationChanges' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarReputationChangesScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Employees' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Financials' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Community' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Environment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPillarReputationChangesQuery,
  GetPillarReputationChangesQueryVariables
>
export const GetReputationScorePotentialDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReputationScorePotential' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationScorePotential' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReputationScorePotentialQuery,
  GetReputationScorePotentialQueryVariables
>
export const GetReputationScorePotentialKeyOpportunityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetReputationScorePotentialKeyOpportunity',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'reputationScorePotentialKeyOpportunity',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReputationScorePotentialKeyOpportunityQuery,
  GetReputationScorePotentialKeyOpportunityQueryVariables
>
export const GetScoreRelevanceGraphDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetScoreRelevanceGraph' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scoresRelevanceGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetScoreRelevanceGraphQuery,
  GetScoreRelevanceGraphQueryVariables
>
export const GetScoresSentimentGraphDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetScoresSentimentGraph' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scoresSentimentGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetScoresSentimentGraphQuery,
  GetScoresSentimentGraphQueryVariables
>
export const GetAlertsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAlerts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alerts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variable_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'variable_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'definition' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avg_score' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stddev' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'daily_avg_score' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'difference' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAlertsQuery, GetAlertsQueryVariables>
